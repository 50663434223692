import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getMileagePerYearOptions } from 'config/data'
import { css } from 'styled-components/macro'
import { media } from '../../utilities/styled'
import { formatMileage } from '../../utilities/utils'
import Button from '../atoms/button'
import NewDropDownSelect from './new-dropdown-select'
import LicensePlate from '../atoms/license-plate'
import ContentSeparator from '../atoms/content-separator'
import NapCheck from './nap-check'
import Typography from './typography'
import Definition from '../atoms/definition'
import CarfileKindSwitch from 'components/molecules/carfile-kind-switch'
import Icon from 'components/atoms/icon'
import FramedImage from 'components/atoms/framed-image'
import CarIdentityPlate from './car-identity-plate'

const Container = styled.header`
  display: flex;
  align-items: stretch;
  flex-direction: row;
`

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`

const RightColumn = styled.div`
  display: none;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizings.lvl2} ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    display: flex;
  `}
`

const MobileRightColumn = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizings.lvl2} ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    display: none;
  `}
`

const MobileNapCheck = styled(NapCheck)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
  display: inline-block;
`

const CarImage = styled.img`
  max-height: ${({ theme }) => theme.sizeByFactor(11)};
  display: none;

  ${media.tablet`
    display: block;
  `}
  @media print {
    display: none;
  }
`
const CarTitle = styled.div`
  padding-left: ${({ theme }) => theme.sizings.lvl2};
  margin-top: ${({ theme }) => theme.sizings.lvl1};
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const Brand = styled(Typography)`
  text-transform: uppercase;
  margin: 0;
`

const Model = styled(Typography)`
  margin: ${({ theme }) => theme.sizings.lvl0} 0 0 0;
`

const Type = styled(Typography)`
  margin: ${({ theme }) => theme.sizings.lvl0} 0 0 0;
`

const PriceLabel = styled(Definition)`
  text-align: right;
  position: relative;
  top: 1px;
`

const StyledLicensePlate = styled(LicensePlate)`
  position: relative;
  ${({ $addSpacing }) => ($addSpacing ? 'top: 2px;' : null)}
`

const StyledDefinition = styled(Definition)`
  margin: ${({ theme }) => theme.sizings.lvl1} 0;
`

const resetButtonCss = css`
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.actionsStandardSecondary};
    svg * {
      // @todo
      // Using !important is the devil. But the Button component is broken,
      // and I don't have time to refactor it. Cutting corners in the interest
      // of time. Remove this when Button component is fixed.
      fill: #fff !important;
    }
  }

  @media print {
    display: none;
  }
`

const ResetButton = styled(Button)`
  ${resetButtonCss}
  align-self: center;
`

const MobileResetButton = styled(Button)`
  ${resetButtonCss}
`

const StyledNewDropDownSelect = styled(NewDropDownSelect)`
  width: 164px;
`

const StyledContentSeparator = styled(ContentSeparator)`
  @media print {
    display: none;
  }
`

const StyledCarfileKindSwitch = styled(CarfileKindSwitch)`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: ${({ theme }) => theme.sizings.lvl3};
  padding-right: ${({ theme }) => theme.sizings.lvl3};

  div {
    margin: 0;
    padding-top: 0;
  }
`

const CarLocation = styled.div`
  display: flex;
  align-items: center;
`

const CarLocationTypography = styled(Typography)`
  font-size: ${({ theme }) => theme.sizings.lvl2};
  font-weight: 500;
`

const StyledFramedImage = styled(FramedImage)`
  max-height: 100%;
  height: ${({ theme }) => theme.sizeByFactor(11)};
  width: ${({ theme }) => theme.sizeByFactor(15.5)};
  overflow: hidden;
`

/**
 * Header used for the regular and valuation car entry methods.
 */
function CarEntryHeader({
  carId,
  imagePath,
  brand,
  buttonIcon,
  buttonTo,
  buttonOnClick,
  className,
  getNap,
  licensePlate,
  model,
  mileage,
  nap,
  price,
  type,
  modelserie,
  year,
  carKind,
  setCarKind,
  location,
  mileagePerYear,
  mileagePerYearOnChange,
  vin,
  carDetailsPreview,
  // NOTE: THIS IS TEMPORARY. IT IS ONLY USED TO GET A DIFFERENT LIST OF POSSIBLE MILEAGES PER YEAR FOR ONE CLIENT.
  dealerId,
  // /NOTE
  ...restProps
}) {
  const { t } = useTranslation()
  const verticalSeperator = (
    <ContentSeparator variant="vertical" paddingLevel={2} />
  )
  const verticalSeparatorHiddenWhenPrinting = (
    <StyledContentSeparator variant="vertical" paddingLevel={2} />
  )
  const mileagePerYearOptions = getMileagePerYearOptions(t, dealerId)

  return (
    <Container
      className={className}
      data-test-e2e="car-entry-header"
      {...restProps}
    >
      <LeftColumn>
        {imagePath && (
          <CarImage
            src={imagePath}
            alt={`${brand} ${model} Exterior`}
            data-test-e2e="image"
          />
        )}
        {!imagePath && carDetailsPreview && (
          <StyledFramedImage
            alt="foto van auto"
            errorMessage="Geen afbeelding beschikbaar"
          />
        )}
        <CarTitle data-test-e2e="car-title">
          {brand && (
            <Brand type="ExplanationParagraph" data-test-e2e="brand">
              {brand}
            </Brand>
          )}
          {model && (
            <Model type="Level3Heading" data-test-e2e="model">
              {`${model}`}
            </Model>
          )}
          {type && (
            <Type type="ExplanationParagraph" data-test-e2e="type">
              {type}
            </Type>
          )}
          {modelserie && (
            <Type type="ExplanationParagraph" data-test-e2e="model-serie">
              {modelserie}
            </Type>
          )}
        </CarTitle>
      </LeftColumn>

      <RightColumn>
        {location && (
          <CarLocation>
            <Icon type="location" size="lg" />
            <CarLocationTypography type="InlineBodyText">
              {location}
            </CarLocationTypography>
          </CarLocation>
        )}

        {carDetailsPreview && (
          <StyledCarfileKindSwitch selected={carKind} setCarKind={setCarKind} />
        )}

        {mileage && licensePlate && nap && (
          <>
            <NapCheck
              getNap={getNap}
              licensePlate={licensePlate}
              mileage={mileage}
              nap={nap}
            />
          </>
        )}

        {(price || licensePlate) && (
          <>
            {getNap && verticalSeperator}
            <div>
              {price && <PriceLabel title={price} value="" />}
              {licensePlate && !carDetailsPreview && (
                <StyledLicensePlate
                  size="small"
                  value={licensePlate}
                  $addSpacing={price && licensePlate}
                  data-test-e2e="license-plate"
                />
              )}
            </div>
          </>
        )}

        {carDetailsPreview && (
          <CarIdentityPlate
            size="big"
            carFileId={carId}
            licensePlate={licensePlate}
            vin={vin}
          />
        )}

        {mileage && (
          <>
            {verticalSeperator}
            <StyledDefinition
              title={t('mileageAbbreviation')}
              value={mileage && formatMileage(mileage)}
              data-test-e2e="mileage"
            />
          </>
        )}

        {year && (
          <>
            {verticalSeperator}
            <StyledDefinition
              title={t('yearOfBuild')}
              value={`${year}`}
              data-test-e2e="year"
            />
          </>
        )}

        {mileagePerYear !== undefined &&
          !mileagePerYearOnChange &&
          (() => {
            const value = mileagePerYearOptions.filter(
              (mileageObject) => mileageObject.value === mileagePerYear,
            )

            const translatedValue =
              value.length > 0 ? value[0].label : mileagePerYear

            return (
              <>
                {verticalSeperator}
                <StyledDefinition
                  title={t('mileagePerYear')}
                  value={translatedValue}
                  data-test-e2e="mileage-per-year"
                />
              </>
            )
          })()}

        {mileagePerYear !== undefined && mileagePerYearOnChange && (
          <>
            {verticalSeperator}
            <div>
              <StyledNewDropDownSelect
                filled
                value={mileagePerYear}
                label={t('mileagePerYear')}
                onChange={mileagePerYearOnChange}
                items={mileagePerYearOptions}
              />
            </div>
          </>
        )}

        {(buttonTo || buttonOnClick) && (
          <>
            {verticalSeparatorHiddenWhenPrinting}
            <ResetButton
              background="actionsStandard"
              level="minimal"
              icon={buttonIcon}
              to={buttonTo}
              onClick={buttonOnClick}
              data-test-e2e="button-reset"
            />
          </>
        )}
      </RightColumn>

      <MobileRightColumn>
        <div>
          {licensePlate && (
            <LicensePlate
              size="tiny"
              value={licensePlate}
              data-test-e2e="license-plate-mobile"
            />
          )}
          <br />
          {getNap && mileage && licensePlate && (
            <MobileNapCheck
              getNap={getNap}
              licensePlate={licensePlate}
              mileage={mileage}
              nap={nap}
              hideExplanation
            />
          )}
          {mileage && (
            <Typography type="InlineBodyText" data-test-e2e="mileage-mobile">
              {formatMileage(mileage)}
            </Typography>
          )}
        </div>
        {(buttonTo || buttonOnClick) && (
          <>
            {verticalSeparatorHiddenWhenPrinting}
            <MobileResetButton
              background="actionsStandard"
              level="minimal"
              icon={buttonIcon}
              to={buttonTo}
              onClick={buttonOnClick}
              data-test-e2e="button-reset-mobile"
            />
          </>
        )}
      </MobileRightColumn>
    </Container>
  )
}

CarEntryHeader.propTypes = {
  carId: PropTypes.string,
  className: PropTypes.string,
  brand: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonTo: PropTypes.string,
  buttonOnClick: PropTypes.func,
  getNap: PropTypes.func,
  licensePlate: PropTypes.string,
  model: PropTypes.string,
  mileage: PropTypes.number,
  nap: PropTypes.object,
  price: PropTypes.string,
  type: PropTypes.string,
  modelserie: PropTypes.string,
  imagePath: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  carKind: PropTypes.object,
  setCarKind: PropTypes.func,
  location: PropTypes.string,
  mileagePerYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** If mileagePerYearOnChange is present,
   * then the expected mileage will be a select component instead of a label */
  mileagePerYearOnChange: PropTypes.func,
  vin: PropTypes.string,
  carDetailsPreview: PropTypes.bool,
  // NOTE: THIS IS TEMPORARY. IT IS ONLY USED TO GET A DIFFERENT LIST OF POSSIBLE MILEAGES PER YEAR FOR ONE CLIENT.
  dealerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CarEntryHeader.defaultProps = {
  carId: '',
  buttonIcon: 'resetSquaredOff',
  brand: undefined,
  className: null,
  nap: undefined,
  getNap: undefined,
  licensePlate: undefined,
  mileage: null,
  imagePath: undefined,
  year: undefined,
  model: undefined,
  price: undefined,
  type: undefined,
  modelserie: undefined,
  buttonTo: undefined,
  buttonOnClick: undefined,
  carKind: {},
  setCarKind: null,
  location: undefined,
  mileagePerYear: undefined,
  mileagePerYearOnChange: undefined,
  vin: undefined,
  carDetailsPreview: false,
  // NOTE: THIS IS TEMPORARY. IT IS ONLY USED TO GET A DIFFERENT LIST OF POSSIBLE MILEAGES PER YEAR FOR ONE CLIENT.
  dealerId: undefined,
}

export default CarEntryHeader
