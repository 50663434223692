import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'

import { formatPhoneNumber, getAppTheme } from 'utilities/utils'

import Link from 'components/atoms/link'
import Text from 'components/atoms/text'

const SupportLink = styled(Link)`
  display: inline-block;
`

const SupportText = styled(Text)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.brandDelta};
  padding: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
  padding-bottom: ${({ theme }) => theme.sizings.lvl1};
`

function AuthFooter({ ...restProps }) {
  const { t } = useTranslation()
  const { themeNameCamelCase } = getAppTheme()
  const theme = useContext(ThemeContext)

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  const { showCopyrightNotice } = theme.login // Show / hide the copyright notice. In whitelabelled apps it's not shown
  const copyrightNotice = t(
    `themeOptions.${themeNameCamelCase}.login.copyrightNotice`,
    { currentYear },
  )

  return (
    <footer {...restProps}>
      <SupportText>
        <Trans i18nKey="needHelp">
          <SupportLink
            href={`tel:${t(`themeOptions.${themeNameCamelCase}.supportPhone`)}`}
            place="phone"
          >
            {{
              phone: formatPhoneNumber(
                t(`themeOptions.${themeNameCamelCase}.supportPhone`),
              ),
            }}
          </SupportLink>
        </Trans>
      </SupportText>

      {showCopyrightNotice && (
        <Text type="body" text={copyrightNotice} color="brandCharlie" />
      )}
    </footer>
  )
}

export default AuthFooter
