import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { unitPrefixes } from 'config/data'
import Definition from 'components/atoms/definition'
import Button from 'components/atoms/button'
import ContentSeparator from 'components/atoms/content-separator'
import Typography from 'components/molecules/typography'
import MediaForm from 'components/organisms/media-form'
import InlinedItems from 'components/layouts/inlined-items'
import { useDispatch } from 'react-redux'
import { deleteCarAssets, getDamage } from 'redux/actions/data'

const ActionsContainer = styled(InlinedItems)`
  margin: 0;
  > * {
    margin-right: ${(props) => props.theme.sizings.lvl1};
  }
`
const StyledOptionButton = styled(Button)`
  padding: 0;
`
// Height: auto; is required to vertically stretch the separator.
const StyledSeparator = styled(ContentSeparator)`
  height: auto;
  align-self: stretch;
`
const StyledDefinition = styled(Definition)`
  margin-bottom: ${(props) => props.theme.sizings.lvl2};
`
const StyledMediaForm = styled(MediaForm)`
  margin-bottom: -16px;
`

function DamageCard({
  className,
  location,
  type,
  costs,
  hideCosts,
  description,
  onDeleteDamage,
  onSelectDamage,
  images,
  optical,
  rotateImages,
  carId,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const formattedCosts = `${unitPrefixes.currency_euro}${costs}`
  let damageTitle = `${type}${hideCosts ? '' : `, ${formattedCosts}`}`
  if (optical && location) {
    damageTitle = `${location}, ${type}${hideCosts ? '' : `, ${formattedCosts}`}`
  }

  async function handleRotate(imageIds, degrees = 'R90') {
    await rotateImages({
      auto_id: carId,
      imageIds,
      deg: degrees,
    })

    dispatch(getDamage({ auto_id: carId }))
  }

  async function handleDelete(selectedImageIds) {
    await deleteCarAssets(carId, selectedImageIds, 'images')

    dispatch(getDamage({ auto_id: carId }))
  }

  return (
    <div className={className}>
      <StyledDefinition title={t('damage')} value={damageTitle} />
      <StyledDefinition title={t('description')} value={description} />
      {images.length > 0 && (
        <StyledMediaForm
          level="standard"
          items={images.map((image) => ({
            id: image.id,
            name: image.id,
            src: image.url,
            type: 'image',
          }))}
          type="images"
          single
          onDelete={handleDelete}
          onRotate={handleRotate}
        />
      )}
      {onDeleteDamage && onSelectDamage && (
        <ActionsContainer>
          <StyledOptionButton
            level="option"
            text={t('delete')}
            onClick={onDeleteDamage}
          />
          <StyledSeparator variant="vertical" />
          <StyledOptionButton
            level="option"
            text={t('modify')}
            onClick={onSelectDamage}
          />
        </ActionsContainer>
      )}
    </div>
  )
}

DamageCard.propTypes = {
  className: PropTypes.string,
  location: PropTypes.string.isRequired,
  type: PropTypes.string,
  costs: PropTypes.string,
  description: PropTypes.string,
  onDeleteDamage: PropTypes.func,
  onSelectDamage: PropTypes.func,
  images: PropTypes.arrayOf(PropTypes.object),
  optical: PropTypes.bool,
  hideCosts: PropTypes.bool,
  rotateImages: PropTypes.func,
  carId: PropTypes.string,
}
DamageCard.defaultProps = {
  className: null,
  type: undefined,
  hideCosts: false,
  costs: undefined,
  description: undefined,
  images: [],
  optical: false,
  onDeleteDamage: null,
  onSelectDamage: null,
  rotateImages: null,
  carId: null,
}

function DamageCards({
  className,
  damages,
  onDeleteDamage,
  onSelectOpticalDamage,
  onSelectTechnicalDamage,
  hideCosts,
  rotateImages,
  carId,
}) {
  const { t } = useTranslation()
  const technicalDamages = damages.filter((damage) => damage.technical)
  const opticalDamages = damages.filter((damage) => !damage.technical)

  function handleSelect(id) {
    const selectedDamage = damages.find((damage) => damage.id === id)

    if (selectedDamage.technical) {
      onSelectTechnicalDamage(selectedDamage)
      return
    }

    onSelectOpticalDamage(selectedDamage)
  }

  function handleDelete(id) {
    if (onDeleteDamage) {
      onDeleteDamage(damages.find((damage) => damage.id === id))
    }
  }

  return (
    <div className={className}>
      {technicalDamages.length > 0 && (
        <>
          <Typography type="Level2Heading">{t('technicalDamages')}</Typography>
          {technicalDamages.map((damage, index) => (
            <div key={`${index}`} data-damage-id={damage.id}>
              <DamageCard
                {...damage}
                data-damage-id={damage.id}
                hideCosts={hideCosts}
                onDeleteDamage={
                  onDeleteDamage ? () => handleDelete(damage.id) : null
                }
                onSelectDamage={
                  onSelectOpticalDamage && onSelectTechnicalDamage
                    ? () => handleSelect(damage.id)
                    : null
                }
                rotateImages={rotateImages}
                carId={carId}
              />
              {index !== technicalDamages.length - 1 && (
                <ContentSeparator paddingLevel={2} />
              )}
            </div>
          ))}
        </>
      )}
      {opticalDamages.length > 0 && (
        <>
          <Typography type="Level2Heading">{t('opticalDamages')}</Typography>
          {opticalDamages.map((damage, index) => (
            <div key={`${index}`} data-damage-id={damage.id}>
              <DamageCard
                {...damage}
                optical
                hideCosts={hideCosts}
                onDeleteDamage={
                  onDeleteDamage ? () => handleDelete(damage.id) : null
                }
                onSelectDamage={
                  onSelectOpticalDamage && onSelectTechnicalDamage
                    ? () => handleSelect(damage.id)
                    : null
                }
                rotateImages={rotateImages}
                carId={carId}
              />
              {index !== opticalDamages.length - 1 && (
                <ContentSeparator paddingLevel={2} />
              )}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

DamageCards.propTypes = {
  className: PropTypes.string,
  damages: PropTypes.array.isRequired,
  onDeleteDamage: PropTypes.func,
  onSelectOpticalDamage: PropTypes.func,
  onSelectTechnicalDamage: PropTypes.func,
  hideCosts: PropTypes.bool,
  rotateImages: PropTypes.func,
  carId: PropTypes.string,
}

DamageCards.defaultProps = {
  className: null,
  onDeleteDamage: null,
  onSelectOpticalDamage: null,
  onSelectTechnicalDamage: null,
  hideCosts: false,
  rotateImages: null,
  carId: null,
}

export default DamageCards
