import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import styled from 'styled-components/macro'

import { addCarImageAssetsForCategory, getCarAssets, getCarMediaAssets, setCarAssetCategory } from 'redux/actions/data'

import { ImageCategories, ImageTypes } from 'config/enums'
import { CAR_CUTTER_CONVERT } from 'config/graphql/v4'

import { withApolloV4Provider } from 'utilities/apollo'
import toast from 'utilities/toast'

import FlexibleDialog from 'components/molecules/flexible-dialog'
import NextPrevCloseButtons, { rightIconWithChevronProps } from 'components/molecules/next-prev-close-buttons'
import ProgressBar from 'components/molecules/progress-bar'
import MediaForm from 'components/organisms/media-form'

const ConversionContainer = styled.div`
  width: 500px; // specified in design.
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizings.lvl2};

  > * {
    flex: 0 0 auto;
  }
`

const ButtonsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  padding: ${({ theme }) => theme.sizings.lvl2} 0;
  width: 100%;
  z-index: 12;
  position: sticky;
  bottom: 0;
`

const CarAssetsFormForMedia = ({
  carAssetsSort,
  carFileId,
  carFile,
  closeText,
  onClose,
  deleteCarAssets,
  level,
  next,
  nextTab,
  previous,
  previousTab,
  rotateImages,
  sortCarAssets,
  optionButtonText,
  onOptionButtonClick,
  hasStickyNav,
  hasChevronInNextPrevCloseButtons,
  isValuation,
}) => {
  const { t } = useTranslation()
  const [selectedImageIds, setSelectedImageIds] = React.useState([])
  const [conversions, setConversions] = React.useState([])
  const [fakeProgress, setFakeProgress] = React.useState(0)
  const [isConverting, setIsConverting] = React.useState(false)
  const [oneSuccessfulConversion, setOneSuccessfulConversion] = React.useState(false)
  const dealerRights = useSelector(state => state?.auth?.userDetails?.dealers)
  const dealersWithAccess = Array.isArray(dealerRights)
    ? dealerRights.filter(dealer => dealer.rechten.carcutter).map(dealer => dealer.id)
    : []

  const hasAccessToPhotoStudio = dealersWithAccess.includes(carFile.vestiging_id)

  const getLabelForCategory = (category) => t(`carFileMediaTab.photosFilter.valueLabels.${category}`)

  const dispatch = useDispatch()

  async function handleUpload (formData) {
    await dispatch(addCarImageAssetsForCategory(formData, carFileId, filterValues.category, t('photoSuccesfullyUploaded')))

    dispatch(getCarAssets(carFileId))

    dispatch(getCarMediaAssets(carFileId, filterValues.category, filterValues.type))
  }

  async function handleDelete (selectedImageIds) {
    await deleteCarAssets(carFileId, selectedImageIds, 'images')

    dispatch(getCarMediaAssets(carFileId, filterValues.category, filterValues.type))
  }

  async function handleSort (newSortOrderData) {
    await sortCarAssets(carFileId, newSortOrderData, filterValues.category)

    dispatch(getCarMediaAssets(carFileId, filterValues.category, filterValues.type))
  }

  async function handleRotate (imageIds, degrees = 'R90') {
    await rotateImages({
      auto_id: carFileId,
      imageIds,
      deg: degrees,
    })

    dispatch(getCarMediaAssets(carFileId, filterValues.category, filterValues.type))
  }

  const handleSelectionChange = (ids) => {
    setSelectedImageIds(ids)
  }

  const conversionErrorMessage = t('carFileMediaTab.genericConversionError')

  const carMediaFilterOptions = [
    {
      label: getLabelForCategory(ImageCategories.INTERNAL),
      value: ImageCategories.INTERNAL,
    },
  ]

  if (!isValuation) {
    if (hasAccessToPhotoStudio) {
      carMediaFilterOptions.push({
        label: t('carFileMediaTab.photosFilter.valueLabels.commercialOriginalPhotos'),
        value: ImageTypes.ORIGINAL,
      })
      carMediaFilterOptions.push({
        label: t('carFileMediaTab.photosFilter.valueLabels.commercialPublishedPhotos'),
        value: ImageTypes.PUBLISHED,
      })
    } else {
      carMediaFilterOptions.push({
        label: getLabelForCategory(ImageCategories.COMMERCIAL),
        value: ImageTypes.ORIGINAL,
      })
    }
  }

  const [conversionDialogIsOpen, setConversionDialogIsOpen] = React.useState(false)

  /**
   * There is a deep rooted bug in the app (probably the Tabs component) that re-mounts the whole
   * page if some props in the Redux state change. This means that this component gets re-mounted if
   * a user changes the order of the photos, thus the image category dropdown gets reset. This hacky
   * workaround uses the browser's location bar to store the filter, and uses that to set the filter
   * when this component mounts.
   **/

  // First off, lets get the filter state from the URL bar, or fall back to a default if there is
  // nothing in the URL
  const defaultFilterValue = isValuation ? ImageCategories.INTERNAL : hasAccessToPhotoStudio ? ImageTypes.PUBLISHED : ImageTypes.ORIGINAL
  const currentUrl = new URL(window.location.href)
  const currentCategoryFromUrl = currentUrl.hash.replace('#', '')
  const matchingOption = currentCategoryFromUrl && carMediaFilterOptions.find(option => option.value.toLowerCase() === currentCategoryFromUrl)
  const initialFilterValue = matchingOption ? matchingOption.value : defaultFilterValue
  const [selectedFilter, setSelectedFilter] = React.useState(initialFilterValue)

  // Update the URL based on the currently selected filter, if it changes
  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const currentCategoryFromUrl = currentUrl.hash.replace('#', '')

    if (currentCategoryFromUrl !== selectedFilter.toLowerCase()) {
      const newHash = `#${selectedFilter.toLowerCase()}`
      window.history.replaceState(null, null, newHash)
    }
  }, [selectedFilter])
  // End of hackyness

  // Map the values for the dropdown to the category and type
  const filterValues = React.useMemo(() => {
    const filterTypeCategoryMapping = {
      [ImageCategories.INTERNAL]: {
        category: ImageCategories.INTERNAL,
        type: ImageTypes.ORIGINAL,
      },
      [ImageTypes.ORIGINAL]: {
        category: ImageCategories.COMMERCIAL,
        type: ImageTypes.ORIGINAL,
      },
      [ImageTypes.PUBLISHED]: {
        category: ImageCategories.COMMERCIAL,
        type: ImageTypes.PUBLISHED,
      },
    }
    return filterTypeCategoryMapping[selectedFilter]
  }, [selectedFilter])

  const otherCategory = filterValues.category === ImageCategories.INTERNAL ? ImageCategories.COMMERCIAL : ImageCategories.INTERNAL

  React.useEffect(() => {
    dispatch(getCarMediaAssets(carFileId, filterValues.category, filterValues.type))
  }, [carFileId, filterValues, dispatch])

  const imageAssets = useSelector(state => Array.isArray(state?.data?.carMediaAssets?.data)
    ? state.data.carMediaAssets.data.filter(({ type }) => type === 'image')
    : [])

  const [applyPhotoStudioToImage] = useMutation(CAR_CUTTER_CONVERT)

  const applyPhotoStudio = () => {
    setConversionDialogIsOpen(true)
    setIsConverting(true)
    setFakeProgress(0)

    const timeout = 120000 // 2 minutes.
    const stepsInTime = 500 // 0.5 seconds
    const incrediment = 100 * (stepsInTime / timeout)

    setConversions(selectedImageIds.map(id => ({
      id,
      name: id,
      inError: false,
    })))

    const conversionMutations = selectedImageIds.map(carImageId => applyPhotoStudioToImage({
      variables: {
        carImageId,
      },
    }).then(response => {
      // If the mutation returns some data, the conversion was successful.
      if (response?.data?.carCutterConvert?.id) { // TODO: Maybe put this carCutterConvert in a constant?
        // The conversion succeeded
        setOneSuccessfulConversion(true)
        setConversions(conversions => conversions.map(conversion => (
          conversion.id === carImageId ? {
            ...conversion,
            progress: 100,
            inError: false,
          } : conversion
        )))
      } else {
        // The conversion failed, no data was returned. This is probably due to an Apollo error.
        // Throw an error and let the catch block handle the rest.
        throw new Error('An error occured')
      }
    }).catch(error => {
      // The conversion failed. This was either due to an Apollo error or a network error (a faulty
      // or no response from the back-end).
      console.error(error)
      setConversions(conversions => conversions.map(conversion => (
        conversion.id === carImageId ? {
          ...conversion,
          progress: 100,
          inError: true,
          message: conversionErrorMessage,
        } : conversion
      )))
    }))

    // start ticking the progress bars:
    const timeTickerRef = setInterval(() => {
      setFakeProgress(progress => progress + incrediment)
    }, stepsInTime)

    const timeoutRef = setTimeout(() => {
      clearInterval(timeTickerRef)
      setConversions(prevConversions => {
        return prevConversions.map(conversion => {
          return {
            ...conversion,
            inError: true,
            message: t('carFileMediaTab.timeoutConversionError'),
          }
        })
      })
      setIsConverting(false)
    }, timeout)

    // Using Promise.allSettled here, since Promise.all will reject if any of it's promises throw
    // an error. This is where the state and timers get cleaned up/reset.
    Promise.allSettled(conversionMutations).finally(() => {
      setIsConverting(false)
      clearInterval(timeTickerRef)
      clearTimeout(timeoutRef)
    })
  }

  const moveToCategoryHandler = async () => {
    // TODO: Fix this for images
    const promises = selectedImageIds.map(id => dispatch(setCarAssetCategory(id, otherCategory)))
    await Promise.all(promises).then((results) => {
      const errors = []
      if (results.error) {
        errors.push(results.error)
      }
      if (errors.length > 0) {
        errors.forEach(error => { console.error(error) })
        throw new Error(t('carFileMediaTab.moveToCategoryError'))
      }
      toast.success(t('carFileMediaTab.moveToCategorySuccess', { category: getLabelForCategory(otherCategory) }))
      // dispatch(getCarImageAssets(carFileId))
      dispatch(getCarMediaAssets(carFileId, filterValues.category, filterValues.type))
    }).catch(error => {
      console.error(error)
      toast.error(error)
    })
  }

  const customFormOptions = []

  if (!isValuation) {
    customFormOptions.push({
      handler: moveToCategoryHandler,
      buttonText: t('carFileMediaTab.moveToCategory', { category: getLabelForCategory(otherCategory) }),
    })
  }

  if (hasAccessToPhotoStudio && filterValues.category === ImageCategories.COMMERCIAL && !isValuation) {
    customFormOptions.push({
      handler: applyPhotoStudio,
      buttonText: t('carFileMediaTab.options.applyPhotoStudio'),
    })
  }

  const handleCloseAction = () => {
    if (oneSuccessfulConversion) {
      setSelectedImageIds([])
      setSelectedFilter(ImageTypes.PUBLISHED)
    }

    // Reset the state and close the upload dialog
    setFakeProgress(0)
    setConversions([])
    setOneSuccessfulConversion(false)
    setConversionDialogIsOpen(false)
  }

  return (
    <>
      <MediaForm
        carAssetsSort={carAssetsSort}
        carId={carFileId}
        items={imageAssets}
        onUpload={handleUpload}
        onDelete={handleDelete}
        onRotate={handleRotate}
        onSort={handleSort}
        onChange={handleSelectionChange}
        level={level || 'standard'}
        type="images"
        itemsFilter={{
          filterOptions: carMediaFilterOptions,
          onChange: setSelectedFilter,
          label: t('carFileMediaTab.photosFilter.label'),
          disabled: isValuation,
        }}
        itemsFilterValue={selectedFilter}
        customOptions={customFormOptions}
        enableReinitialize
        isValuation={isValuation}
      />
      {next || previous || onClose
        ? (
          <ButtonsContainer>
            <NextPrevCloseButtons
              closeText={closeText}
              previousTab={previous && previousTab}
              nextTab={next && nextTab}
              onClose={onClose}
              hasStickyNav={hasStickyNav}
              optionButtonText={optionButtonText}
              onOptionButtonClick={onOptionButtonClick}
              {...(hasChevronInNextPrevCloseButtons && rightIconWithChevronProps)}
            />
          </ButtonsContainer>
        )
        : null}
      <FlexibleDialog
        content={
          <ConversionContainer>
            {conversions.map(conversion => (
              <ProgressBar
                key={conversion.id}
                title={t('carFileMediaTab.photoStudioConversion.photoLabel', {
                  name: conversion.name,
                })}
                progress={typeof conversion.progress === 'number' ? conversion.progress : fakeProgress}
                inError={conversion.inError}
                message={conversion.message}
              />
            ))}
          </ConversionContainer>
        }
        disabled={isConverting}
        submitText={t('carFileMediaTab.photoStudioConversion.buttonText')}
        onSubmit={handleCloseAction}
        title={t('carFileMediaTab.photoStudioConversion.heading')}
        closeHandler={handleCloseAction}
        open={conversionDialogIsOpen}
      />
    </>
  )
}

CarAssetsFormForMedia.propTypes = {
  carFileId: PropTypes.string.isRequired,
  carAssetsSort: PropTypes.object,
  carFile: PropTypes.object,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
  deleteCarAssets: PropTypes.func.isRequired,
  level: PropTypes.string,
  next: PropTypes.bool,
  nextTab: PropTypes.func,
  previous: PropTypes.bool,
  previousTab: PropTypes.func,
  rotateImages: PropTypes.func,
  sortCarAssets: PropTypes.func.isRequired,
  optionButtonText: PropTypes.string,
  onOptionButtonClick: PropTypes.func,
  hasStickyNav: PropTypes.bool,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
  isValuation: PropTypes.bool,
}

CarAssetsFormForMedia.defaultProps = {
  carAssetsSort: null,
  carFile: null,
  closeText: null,
  level: null,
  next: null,
  nextTab: null,
  previous: null,
  previousTab: null,
  onClose: null,
  rotateImages: null,
  items: null,
  itemsFilter: MediaForm.defaultProps.itemsFilter,
  itemsFilterValue: MediaForm.defaultProps.itemsFilterValue,
  customOptions: MediaForm.defaultProps.customOptions,
  optionButtonText: null,
  onOptionButtonClick: null,
  hasStickyNav: false,
  hasChevronInNextPrevCloseButtons: false,
  isValuation: false,
}

export default withApolloV4Provider(CarAssetsFormForMedia)
