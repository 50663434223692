import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/client'

import {
  contractStatusColors,
  contractStatuses,
  documentStatuses,
  getContractStatusLabels,
  targetContractStatuses,
  warrantyPackageTypesPeriods,
} from 'config/data'
import { GET_CONTRACT, UPLOAD_SIGNED_DOCUMENT } from 'config/graphql/csp'
import { CSP, CSP_CONTRACT_FILE } from 'config/routes'
import { MINIMUM_WEEKS_TO_ENABLE_CONTRACT_MODIFICATION } from 'config/consts'

import {
  formatDate,
  formatLicenseplate,
  formatMileage,
  formatPrice,
} from 'utilities/format'
import { media } from 'utilities/styled'
import toast from 'utilities/toast'

import Button from 'components/atoms/button'
import ContentSeparator from 'components/atoms/content-separator'
import Label from 'components/atoms/label'
import LoadingIndicator from 'components/atoms/loading-indicator'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import InlinedItems from 'components/layouts/inlined-items'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import CarEntryHeader from 'components/molecules/car-entry-header'
import DocumentContainer from 'components/molecules/document-container'
import SelectFileButton from 'components/molecules/select-file-button'
import StaticValuesCollection from 'components/molecules/static-values-collection'
import Typography from 'components/molecules/typography'
import PageHeader from 'components/organisms/page-header'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'

import ChangeContractDialog from './dialogs/change-contract-dialog'
import ChangeContractStatusDialog from './dialogs/change-contract-status-dialog'
import DoMileageCheckDialog from './dialogs/mileageCheckDialog'
import CspSalesCustomerDriverForm from './steps/csp-sales-customer-driver-form'

const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
      padding: ${({ theme }) => theme.sizings.lvl2} 60px;
  `}
`
const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
const TwoColumnPageGrid = styled(TwoColumnGrid)`
  gap: 0 ${({ theme }) => theme.sizings.lvl5};
`
const StyledInlinedItems = styled(InlinedItems)`
  margin-top: 0;
`
const DocumentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizings.lvl2};
  > * {
    flex: 0 0 calc(50% - ${({ theme }) => theme.sizings.lvl2});
  }
`
const TypographyNoMargin = styled(Typography)`
  margin: 0;
`
const ContractStatusLabel = styled(Label)`
  margin-left: ${({ theme }) => theme.sizings.lvl2};
`
const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const DocumentContainerStyled = styled(DocumentContainer)`
  width: auto;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -${({ theme }) => theme.sizings.lvl2};

  > *:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  }

  > *:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const { SIGNED, ACTIVE } = contractStatuses
const { UNSIGNED, STOPPED } = documentStatuses

const mapDocument = (document, t) => ({
  ...document,
  key: document.id,
  parentDocumentId: document.documentId,
  uri: document.fullUri || '',
  useFilenameAsTitle: false,
  title: document.filename,
  documentIcon: document.filename.split('.').pop(),
  action1Label: t('download'),
  onAction1Click: () => {
    window.open(document.fullUri, '_blank')
  },
})

const CarServicePlanContractDetails = ({ match }) => {
  const { t } = useTranslation()
  const { id } = match.params
  const contract = useQuery(GET_CONTRACT, { variables: { id } })
  const [changeContractStatusModal, setChangeContractStatusModal] =
    useState(false)
  const [changeContractModal, setChangeContractModal] = useState(false)
  const [mileageCheckModal, setMileageCheckModal] = useState(false)

  const refetchQueries = {
    refetchQueries: [
      { query: GET_CONTRACT, variables: { id }, fetchPolicy: 'network-only' },
    ],
    awaitRefetchQueries: true,
  }
  const [uploadSignedContractDocument] = useMutation(
    UPLOAD_SIGNED_DOCUMENT,
    refetchQueries,
  )

  if (contract.loading) {
    return <LoadingIndicator error={null} />
  }
  if (contract.error || !contract?.data?.contract) {
    console.error(contract.error || !contract?.data)
    return (
      <AppLayoutContainer
        pageTitle={`${t('carServicePlanContractDetails.title')} ${t('carServicePlanContractDetails.status')}`}
      >
        <Container>
          <PageHeader
            mainTitle={t('carServicePlanContractDetails.title')}
            status={t('carServicePlanContractDetails.status')}
            hasBorderBottom={false}
          />
          <Typography type="Level2Heading">
            {t('problemsFoundHeading')}
          </Typography>
          <Typography type="ExplanationParagraph">{contract.error}</Typography>
        </Container>
      </AppLayoutContainer>
    )
  }

  const documents = contract.data.contract.documents
    .filter((document) => document.status !== UNSIGNED)
    .map((document) => mapDocument(document, t))

  const unsignedDocuments = contract.data.contract.documents
    .filter(
      (unsignedDocument) =>
        unsignedDocument.status === UNSIGNED &&
        contract.data.contract.documents.find(
          (document) => document.documentId === unsignedDocument.id,
        ) === undefined,
    )
    .map((unsignedDocument) => mapDocument(unsignedDocument, t))

  const documentsToShow = [].concat(documents, unsignedDocuments)
  documentsToShow.sort((documentA, documentB) => {
    return typeof documentB.createdAt === 'string' &&
      typeof documentA.createdAt === 'string'
      ? documentB.createdAt.localeCompare(documentA.createdAt)
      : 0
  })

  const groupedDocumentsToShow = []
  documentsToShow.forEach((document) => {
    const foundDateGroup = groupedDocumentsToShow.find((dateGroup) => {
      return (
        moment(document.createdAt).isSame(dateGroup.date, 'day') &&
        dateGroup.status === document.status
      )
    })
    if (foundDateGroup) {
      // we can just push the new value, because sorting is done earlier.
      foundDateGroup.documents.push(document)
    } else {
      // add as a new dateGroup:
      // we can just push the new value, because sorting is done earlier.
      groupedDocumentsToShow.push({
        date: document.createdAt,
        status: document.status,
        documents: [document],
      })
    }
  })

  const handleUpload = (file, unsignedDocument) => {
    const upload = uploadSignedContractDocument({
      variables: {
        documentId: unsignedDocument.id,
        file,
      },
      context: {
        hasUploads: true,
      },
    })

    upload
      .then(() => {
        toast.success(t('cspSalesFlow.steps.documents.uploadFinished'))
      })
      .catch(() => {
        toast.error(t('cspSalesFlow.steps.documents.uploadError'))
      })
  }
  const contractStatus = contract.data.contract.status
  const targetStatus = targetContractStatuses[contractStatus]
  const carPicture = contract.data.contract.car.carPictures.find(
    (picture) => picture.type === 'EXTERIOR',
  )

  // A user can edit the contract details if the contract meets certain conditions
  // 1) Contracts has SIGNED or ACTIVE status
  // 2) Contract has no stop date
  // OR
  // 2) Contract has a stop date which is in the future
  const userFormIsEditable =
    [SIGNED, ACTIVE].includes(contract.data.contract.status) &&
    (!contract.data.contract.stopDate ||
      moment(contract.data.contract.stopDate).isAfter(moment(), 'day')) // Check if stopdate is after today

  const deactivationStaticValues = [
    {
      title: t(
        'carServicePlanContractDetails.packageInfoTable.contractStoppedDate',
      ),
      value: formatDate(contract.data.contract.stopDate),
    },
  ]
  if (contract.data.contract.explanation) {
    deactivationStaticValues.push({
      title: t('carServicePlanContractDetails.packageInfoTable.explanation'),
      value: contract.data.contract.explanation,
    })
  }
  if (contract.data.contract.reason && contract.data.contract.reason.message) {
    deactivationStaticValues.push({
      title: t(
        'carServicePlanContractDetails.packageInfoTable.deactivationReason',
      ),
      value: contract.data.contract.reason.message,
    })
  }

  const activeCalculation = contract.data.contract.calculation.results.find(
    (result) => result.id === contract.data.contract.packageCalculationResultId,
  )

  const staticValues = [
    {
      title: t('carServicePlanContractDetails.packageInfoTable.package'),
      value: contract.data.contract.package.name,
    },
    {
      title: t(
        'carServicePlanContractDetails.packageInfoTable.contractStartDate',
      ),
      value: formatDate(contract.data.contract.activationDate),
    },
    {
      title: t('carServicePlanContractDetails.packageInfoTable.closedOn'),
      value: formatDate(contract.data.contract.calculation.saleDate),
    },
    {
      title: t(
        'carServicePlanContractDetails.packageInfoTable.contractEndDate',
      ),
      value: formatDate(contract.data.contract.expirationDate),
    },
    {
      title: t(
        'carServicePlanContractDetails.packageInfoTable.contractDuration',
      ),
      value: `${warrantyPackageTypesPeriods[contract.data.contract.package.packageType]} ${t('carValuationMonths').toLowerCase()}`,
    },
    {
      title: t('carServicePlanContractDetails.packageInfoTable.pricePerMonth'),
      value: activeCalculation?.resultNet
        ? formatPrice(activeCalculation.resultNet, true, true, true)
        : undefined,
    },
    {
      title: t(
        'carServicePlanContractDetails.packageInfoTable.maxMileagePerYear',
      ),
      value:
        contract.data.contract.calculation.mileagePerYear > 0
          ? formatMileage(contract.data.contract.calculation.mileagePerYear)
          : undefined,
    },
  ]

  const today = new Date()
  const weeksToExpiration = contract.data.contract.expirationDate
    ? moment(contract.data.contract.expirationDate, 'YYYY-MM-DD').diff(
        today,
        'weeks',
      )
    : 0
  const contractExpiresSoon =
    weeksToExpiration <= MINIMUM_WEEKS_TO_ENABLE_CONTRACT_MODIFICATION

  return (
    <Container>
      <PageHeader
        mainTitle={t('carServicePlanContractDetails.title')}
        status={t('carServicePlanContractDetails.status')}
        hasBorderBottom={false}
        optionsSlot={
          <ButtonGroup>
            {targetStatus !== STOPPED && (
              <Button
                level="option"
                text={t(
                  'carServicePlanContractDetails.mileageCheck.buttonLabel',
                )}
                onClick={() => setMileageCheckModal(true)}
                data-test-e2e="button-mileage-check"
              />
            )}
            {targetStatus !== STOPPED && (
              <Button
                level="option"
                text={t(
                  'carServicePlanContractDetails.changeContract.buttonLabel',
                )}
                onClick={() => setChangeContractModal(true)}
                data-test-e2e="button-change-contract"
                disabled={contractExpiresSoon}
              />
            )}
            {targetStatus && (
              <Button
                level="option"
                text={t(
                  `carServicePlanContractDetails.${targetStatus}.heading`,
                )}
                onClick={() => setChangeContractStatusModal(true)}
                data-test-e2e="button-cancel-contract"
              />
            )}
          </ButtonGroup>
        }
      />
      <StyledBreadcrumbs
        items={[
          { label: t('carServicePlanDashboard.cspDashboard'), to: CSP },
          { label: `${t('file')} ${id}`, to: `${CSP_CONTRACT_FILE}/${id}` },
        ]}
      />
      <FullWidthTopBarLayout
        topBarNoPaddingSlot={
          <CarEntryHeader
            carId={contract.data.contract.car.id}
            imagePath={carPicture ? carPicture.url : undefined}
            brand={contract.data.contract.car.brand.name}
            model={contract.data.contract.car.model.name}
            mileage={contract.data.contract.car.mileage}
            type={`${contract.data.contract.car.engineCapacity}cc`}
            licensePlate={contract.data.contract.car?.licensePlate}
            year={moment(contract.data.contract.car?.registrationDate).year()}
          />
        }
        mainSlot={
          <TwoColumnPageGrid>
            <div>
              <StyledInlinedItems hasVerticalMargins>
                <TypographyNoMargin type="Level1Heading">
                  {t('carServicePlanContractDetails.headingMaintenancePackage')}
                </TypographyNoMargin>
                <ContractStatusLabel
                  text={
                    getContractStatusLabels(t)[contract.data.contract.status]
                  }
                  backgroundColor={
                    contractStatusColors[contract.data.contract.status]
                  }
                />
              </StyledInlinedItems>
              {(contract.data.contract.stopDate ||
                contract.data.contract.explanation ||
                (contract.data.contract.reason &&
                  contract.data.contract.reason.message)) && (
                <>
                  <StaticValuesCollection
                    collection={deactivationStaticValues}
                  />
                  <ContentSeparator paddingLevel={3} />
                </>
              )}
              <StaticValuesCollection collection={staticValues} />
              <Typography type="Level1Heading">
                {t('carServicePlanContractDetails.headingCustomerData')}
              </Typography>
              <CspSalesCustomerDriverForm
                initialCustomer={contract.data.contract.customer}
                initialDriver={contract.data.contract.driver}
                initialCar={
                  contract.data.contract?.car
                    ? {
                        carId: contract.data.contract.car.id,
                        registrationDate:
                          contract.data.contract.car.registrationDate,
                        licensePlate: formatLicenseplate(
                          contract.data.contract.car.licensePlate,
                        ),
                      }
                    : undefined
                }
                readOnly={!userFormIsEditable}
                primaryActionLabel={t('saveChanges')}
                contractId={id}
                onSubmit={() => {}}
              />
            </div>
            <div>
              <Typography type="Level1Heading">
                {t('carServicePlanContractDetails.title')}
              </Typography>
              {Array.isArray(groupedDocumentsToShow) &&
                groupedDocumentsToShow.length > 0 &&
                groupedDocumentsToShow.map((dateGroup) => (
                  <React.Fragment key={formatDate(dateGroup.date)}>
                    <Typography type="Level2Heading">
                      {t(
                        `carServicePlanContractDetails.documents.${dateGroup.status}`,
                        {
                          date: formatDate(dateGroup.date),
                        },
                      )}
                    </Typography>
                    <DocumentsContainer>
                      {dateGroup.documents.map((document) => (
                        <div key={`document_${document.id}`}>
                          <DocumentContainerStyled {...document} />
                          {document.status === UNSIGNED && (
                            <SelectFileButton
                              id={`uploadSignedDocument_${document.id}`}
                              text={t(
                                'cspSalesFlow.steps.documents.uploadDocument',
                              )}
                              allowedFileExtensions={['.doc', '.docx', '.pdf']}
                              onChange={(formData) => {
                                handleUpload(formData.get('file'), document)
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </DocumentsContainer>
                  </React.Fragment>
                ))}
            </div>
          </TwoColumnPageGrid>
        }
      />
      {changeContractStatusModal && (
        <ChangeContractStatusDialog
          contract={contract.data.contract}
          onClose={() => setChangeContractStatusModal(false)}
        />
      )}
      {changeContractModal && (
        <ChangeContractDialog
          contract={contract.data.contract}
          onClose={() => setChangeContractModal(false)}
        />
      )}
      {mileageCheckModal && (
        <DoMileageCheckDialog
          contract={contract.data.contract}
          onClose={() => setMileageCheckModal(false)}
        />
      )}
    </Container>
  )
}

CarServicePlanContractDetails.propTypes = {
  match: PropTypes.object.isRequired,
}

export default CarServicePlanContractDetails
