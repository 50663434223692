import * as routes from 'config/routes'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import { isSubmitting } from 'redux-form'
import styled from 'styled-components/macro'

import { mapCarFileType } from 'utilities/mapping'
import { media } from 'utilities/styled'
import {
  getBackendNapValue,
  getObjectFromQueryString,
  scrollToTop,
} from 'utilities/utils'

import { getCarfile } from 'redux/actions/data'

import LoadingIndicator from 'components/atoms/loading-indicator'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import CarEntryHeader from 'components/molecules/car-entry-header'
import FlowStepper from 'components/molecules/flow-stepper'
import CarEntryTabs from 'components/organisms/car-entry-tabs'
import PageHeader from 'components/organisms/page-header'

const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
      padding: ${({ theme }) => theme.sizings.lvl2} 60px;
  `}
`

const StyledPageHeader = styled(PageHeader)`
  padding: 0 0 ${({ theme }) => theme.sizings.lvl4};
  ${media.tablet`
    padding: ${({ theme }) => theme.sizings.lvl3} 0;
  `}
`

const StyledCarEntryHeader = styled(CarEntryHeader)`
  border-bottom: ${({ theme }) =>
    `${theme.colors.darkOnLightBorder} 1px solid`};
`

function CarEntryStep2({
  createCarfile,
  forms,
  getForms,
  getNap,
  match,
  nap,
  newCar,
  updateCarfile,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = getObjectFromQueryString(location.search)
  const carId = queryParams && queryParams.auto_id
  const carFile = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )
  const carFileLoading = useSelector((state) => {
    const { data } = state
    return (
      (data.createCarfile && data.createCarfile.loading) ||
      (data.updateCarfile && data.updateCarfile.loading)
    )
  })
  const isFormSubmitting = useSelector(
    (state) => state?.form?.carEntry && isSubmitting('carEntry')(state),
  )

  const stepperItemsQueryParam = carId ? `?auto_id=${carId}` : ''

  const stepperItems = [
    {
      active: location.pathname === `${match.url}/basic`,
      label: t('basicData'),
      link: `${match.url}/basic${stepperItemsQueryParam}`,
    },
    {
      active: location.pathname === `${match.url}/specifications`,
      disabled: location.pathname === `${match.url}/basic`,
      label: t('specifications'),
      link: `${match.url}/specifications${stepperItemsQueryParam}`,
    },
    {
      active: location.pathname === `${match.url}/history`,
      disabled: location.pathname === `${match.url}/basic`,
      label: t('history'),
      link: `${match.url}/history${stepperItemsQueryParam}`,
    },
    {
      active: location.pathname === `${match.url}/equipment`,
      disabled: location.pathname === `${match.url}/basic`,
      label: t('equipment'),
      link: `${match.url}/equipment${stepperItemsQueryParam}`,
    },
  ]

  const napValue = nap && nap.data && nap.data.nap
  const napBackendValue = getBackendNapValue(napValue)
  const car = {
    ...newCar,
    ...carFile,
    nap: napBackendValue,
  }
  const formId = 'carEntry'

  useEffect(() => {
    if (carId && !carFile) {
      dispatch(getCarfile(carId))
    }
  }, [carFile, carId, dispatch])

  useEffect(() => {
    getForms('carfile', { kenteken: car.kenteken, auto_id: carId })
    // has no dependencies, so it will only run once on componentDidMount and
    // on at that time. This ensures that a clean version of the forms config
    // is retrieved for car entry, otherwise that forms for the last visited
    // carFile are used.
    // documented in: https://app.clickup.com/t/6unbvp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!forms) {
      getForms('carfile', { kenteken: car.kenteken, auto_id: carId })
    }
  }, [carFile, car.kenteken, carId, forms, getForms])

  useEffect(() => {
    scrollToTop()
  }, [])

  const entryProcessStartLink = useSelector((state) => {
    const actions = state?.data?.menus?.data?.actions
    if (Array.isArray(actions)) {
      const link = actions.find((action) => action.id === 'input')?.link
      if (link) {
        return link
      }
    }
    return `${routes.CAR_ENTRY}/1`
  })

  return (
    <Container>
      <StyledPageHeader
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('entry')}
        subtitle={t('step22AddData')}
      />
      <FullWidthTopBarLayout
        paddingLevelTopBar={2}
        paddingLevelMain={0}
        topBarNoPaddingSlot={
          <StyledCarEntryHeader
            brand={car.merk}
            model={car.model}
            modelserie={car.uitvoeringen?.length ? car.type : car.modelserie}
            licensePlate={car.kenteken}
            mileage={car.km_binnen}
            getNap={getNap}
            nap={nap}
            buttonTo={entryProcessStartLink}
          />
        }
        topBarSlot={<FlowStepper items={stepperItems} />}
        mainSlot={
          !forms || forms.loading || carFileLoading || isFormSubmitting ? (
            <LoadingIndicator />
          ) : (
            <CarEntryTabs
              car={car}
              createCarfile={createCarfile}
              formId={formId}
              forms={forms.data}
              updateCarfile={updateCarfile}
              getForms={getForms}
            />
          )
        }
      />
    </Container>
  )
}

CarEntryStep2.propTypes = {
  createCarfile: PropTypes.func.isRequired,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  getNap: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  nap: PropTypes.object,
  newCar: PropTypes.object,
  updateCarfile: PropTypes.func.isRequired,
}

CarEntryStep2.defaultProps = {
  forms: null,
  nap: undefined,
  newCar: null,
}

export default withRouter(CarEntryStep2)
