import React from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import data from 'utilities/data'

import ComplexForm from 'components/redux-containers/complex-form-container'
import CarFileQuickPriceValuation from 'components/molecules/carfile-quick-price-valuation'

import { VALUATION } from 'config/routes'

const StyledCarFileQuickPriceValuation = styled(CarFileQuickPriceValuation)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

function CarDataForm({
  className,
  carFile,
  fieldsets,
  manual,
  valuation,
  hasStickyNav,
  hasChevronInNextPrevCloseButtons,
  ...restProps
}) {
  const match = useRouteMatch()
  const { injectCarFileDataInComplexFormFieldsets } = data
  const enrichedFieldsets = carFile
    ? injectCarFileDataInComplexFormFieldsets(fieldsets, carFile.auto_id)
    : fieldsets

  return (
    <div className={className}>
      {
        // Displays the 'Quick Price Valuation' component only when:
        // - the user is in the first step of the tax valuation flow
        // - the car has a license plate (for unknown license plate, it's impossible to do the calculation)
        // - the dealer is based in the Netherlands (there are no valuation API's available for other countries)
        match.path === `${VALUATION}/2/basic` && (
          <StyledCarFileQuickPriceValuation />
        )
      }

      <ComplexForm
        data={carFile}
        fieldsets={enrichedFieldsets}
        enableReinitialize
        hasStickyNav={hasStickyNav}
        hasChevronInNextPrevCloseButtons={hasChevronInNextPrevCloseButtons}
        carFileDetails
        {...restProps}
      />
    </div>
  )
}

CarDataForm.propTypes = {
  className: PropTypes.object,
  carFile: PropTypes.object,
  fieldsets: PropTypes.arrayOf(PropTypes.object),
  manual: PropTypes.bool,
  valuation: PropTypes.bool,
  hasStickyNav: PropTypes.bool,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
}

CarDataForm.defaultProps = {
  className: null,
  carFile: null,
  fieldsets: [],
  manual: false,
  valuation: false,
  hasStickyNav: false,
  hasChevronInNextPrevCloseButtons: false,
}

export default CarDataForm
