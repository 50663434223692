import { ClickAwayListener } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { useMediaLayout } from 'use-media'

import { media } from 'utilities/styled'

import { BidsProviderIds } from 'config/enums'

import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'
import useDealerLocationCountryCode from 'hooks/use-location-country-code'

import { getForms } from 'redux/actions/forms'

import Button from 'components/atoms/button'
import DatePicker from 'components/atoms/new-date-picker'
import VerticalLayout from 'components/layouts/vertical-layout'
import BidsOverview from 'components/molecules/bids-overview'
import Modal from 'components/molecules/modal'
import NextPrevCloseButtons, {
  rightIconWithChevronProps,
} from 'components/molecules/next-prev-close-buttons'
import PopupMenu from 'components/molecules/popup-menu'
import SelectModal from 'components/molecules/select-modal'
import Typography from 'components/molecules/typography'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  max-width: 280px;
`

const StyledButton = styled(Button)`
  ${media.tablet`
    margin-top: -12px;
  `}
`

const StyledPopupMenu = styled(PopupMenu)`
  .actual-menu {
    top: -12px;
    right: 0;
  }
`

const StyledSelectModal = styled(SelectModal)`
  & button {
    padding-left: 0;
    padding-right: 0;

    & span {
      padding-right: 0;

      &:last-child {
        padding-left: 0;
      }
    }
  }
`

const MissingDataModal = styled(Modal)`
  ${media.tablet`
    .MuiDialog-container {
      align-items: center;
    }
  `}
`

const ButtonsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  padding: ${({ theme }) => theme.sizings.lvl0} 0;
  width: 100%;
  z-index: 12;
  position: sticky;
  bottom: 0;
`
function BidsForm({
  className,
  bids,
  carFile,
  closeText,
  dynamicEndpoint,
  getBids,
  next,
  nextTab,
  onClose,
  openOverlay,
  postDynamicEndpoint,
  previousTab,
  previous,
  updateCarfile,
  valuation,
  optionButtonText,
  onOptionButtonClick,
  hasStickyNav,
  hasChevronInNextPrevCloseButtons,
  carFileDetails,
  ...restProps
}) {
  const {
    auto_id: carId,
    datum_verwacht: carExpectedDate,
    kenteken: carLicensePlate,
  } = carFile
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const forms = useSelector((state) => state.forms)
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  const bidProvidersData = bids?.data || []
  const bidProvidersWithBids = bidProvidersData.filter(
    (bidProvider) => bidProvider.bids,
  )
  const bidProvidersWithButton = bidProvidersData.filter(
    (bidProvider) => bidProvider.button,
  )

  let bidProviders = bidProvidersWithButton

  // When the location (find this in userDetails in the Redux store) *is NOT* NL,
  // we have to filter Indicata and JP.Cars from the bid providers.
  // These providers can't give data about license plates outside of the Netherlands
  bidProviders = !locationCountryCodeIsNl
    ? bidProvidersWithButton?.filter(
        (bidProvider) =>
          ![BidsProviderIds.INDICATA, BidsProviderIds.JPCARS].includes(
            bidProvider.id,
          ),
      )
    : bidProvidersWithButton

  // When the location (find this in userDetails in the Redux store) *is* NL,
  // we have to filter Indicata from the bid providers when there's no license plate available
  // Only "autotelex" (deprecated) can supply data when there's no license plate
  bidProviders = !carLicensePlate
    ? bidProviders?.filter(
        (bidProvider) =>
          ![BidsProviderIds.INDICATA].includes(bidProvider.id) &&
          ![BidsProviderIds.JPCARS].includes(bidProvider.id),
      )
    : bidProviders

  const [missingDataModalOpen, setMissingDataModalOpen] = useState(false)
  const [newBidSelectModalOpen, setNewBidSelectModalOpen] = useState(false)
  const [selectedBidProviderAction, setSelectedBidProviderAction] = useState({})

  const handleOpenOverlay = useCallback(
    (overlay, data) => {
      if (overlay.form_id) {
        return openOverlay('form', {
          data,
          title: t('valuationIndication'),
          formCategory: overlay.form_category,
          formId: overlay.form_id,
          storeFormId: overlay.form_id,
          icon: 'user',
          onSubmit: (values) => {
            const form = forms[overlay.form_category].data[overlay.form_id]
            const formEndpoint = form.endpoint
            postDynamicEndpoint({
              endpoint: formEndpoint,
              ...values,
            })
          },
          submitText: t('submit'),
          // update content with current data each time it opens:
          enableReinitialize: true,
        })
      } else {
      }
    },
    [forms, openOverlay, postDynamicEndpoint, t],
  )

  /**
   * Handling the actual opening of a bidProvider actions through a useEffect,
   * because trying to handling it a a delarative way (in simple steps).
   * causes a race condition, because redux needs a little time to update the
   * store with the newly retrieved form.
   */
  useEffect(() => {
    if (
      selectedBidProviderAction?.overlay &&
      forms?.[selectedBidProviderAction.overlay.form_category]?.data?.[
        selectedBidProviderAction.overlay.form_id
      ]
    ) {
      handleOpenOverlay(
        selectedBidProviderAction.overlay,
        selectedBidProviderAction.data,
      )
    }
  }, [forms, selectedBidProviderAction, handleOpenOverlay])

  function getButtonClickHandler(openBidOverlayButton, data) {
    const { endpoint, overlay } = openBidOverlayButton
    /**
     * the button config will either contain an endpoint definition (string) or an overlay definition (object).
     * if it contains just an endpoint this needs to be called directly.
     *
     * If it contains a overlay config this means that the eventual endpoint needs some input via an
     * form in an overlay.
     */
    if (endpoint && !overlay) {
      return postDynamicEndpoint({
        endpoint,
      })
    }
    if (overlay?.form_id) {
      setSelectedBidProviderAction({ overlay, data })
      dispatch(getForms('bids', { auto_id: carId, form_id: overlay.form_id }))
    }
  }

  const handleOpenNewBidSelectModal = () => {
    setNewBidSelectModalOpen(true)
  }

  const handleCloseNewBidSelectModal = () => {
    setNewBidSelectModalOpen(false)
  }

  useEffect(() => {
    getBids({ auto_id: carId })
    handleCloseNewBidSelectModal()
  }, [carId, getBids])

  useDynamicEndpointDone(
    dynamicEndpoint,
    () => {
    getBids({ auto_id: carId })
    handleCloseNewBidSelectModal()
  },
  [getBids, carId],
)

  return (
    <>
      <VerticalLayout className={className} {...restProps}>
        <HeaderContainer>
          <Typography type="Level4Heading">
            {t('valuationIndication')}
          </Typography>

          <ClickAwayListener
            onClickAway={!isMobile ? handleCloseNewBidSelectModal : () => null}
          >
            <div>
              <StyledPopupMenu
                items={bidProviders.map((bidProvider) => ({
                  label: bidProvider.label,
                  onClick: () => {
                    getButtonClickHandler(bidProvider.button)
                  },
                }))}
                positionMenu={{ toLeft: true }}
                isMenuActive={!isMobile && newBidSelectModalOpen}
              >
                <StyledButton
                  background="actionsStandard"
                  icon="add"
                  onClick={() => handleOpenNewBidSelectModal()}
                  level="minimal"
                  data-test-e2e="button-new-bid-modal"
                />
              </StyledPopupMenu>
            </div>
          </ClickAwayListener>
        </HeaderContainer>

        {valuation && (
          <DatePicker
            label={t('expectedCarArrivalDate')}
            name="datum_verwacht"
            onChange={(date) => {
              if (date !== carExpectedDate) {
                updateCarfile(
                  {
                    datum_verwacht: date,
                  },
                  carId,
                )
              }
            }}
            required
            filled
            placeholder={t('expectedCarArrivalDateHint')}
            value={carExpectedDate}
            data-test-e2e="expected-car-arrival-date-picker"
          />
        )}

        {locationCountryCodeIsNl && carFile?.gaspedaal_url && (
          <ButtonContainer>
            <Button
              width="320px"
              level="standard"
              href={carFile.gaspedaal_url}
              _blank
              data-test-e2e="button-gaspedaal"
            >
              {t('checkGaspedaal')}
            </Button>
          </ButtonContainer>
        )}

        {/* TODO: Backend needs to map all brands and models to third party platforms. See https://app.clickup.com/t/2501750/UCC-5860 */
        /* {!locationCountryCodeIsNl && (
          <ButtonContainer>
            <Button
              width="320px"
              level="standard"
              onClick={() => window.open(`https://www.autoscout24.${locationCountryCode}/lst/${prepareLinkVariable(carFile.brand)}/${prepareLinkVariable(carFile.model)}/`, '_blank')}
              data-test-e2e="button-gaspedaal"
            >
              {t('checkAutoscout')}
            </Button>
          </ButtonContainer>
        )} */}

        {bidProvidersWithBids && bidProvidersWithBids.length > 0 ? (
          bidProvidersWithBids.map((bidProvider) => (
            <BidsOverview
              key={`bid-overview-${bidProvider.id}`}
              bids={bidProvider.bids}
              button={bidProvider.button}
              carId={carId}
              forms={forms}
              logo={bidProvider.logo}
              openOverlay={openOverlay}
              postDynamicEndpoint={postDynamicEndpoint}
              title={bidProvider.label}
              handleOpenBid={getButtonClickHandler}
            />
          ))
        ) : (
          <>
            <Typography type="ExplanationParagraph">
              {t('noValuationsYet')}
            </Typography>
            <Typography type="ExplanationParagraph">
              {t('clickButtonToAddValuation')}
            </Typography>
          </>
        )}

        {next || previous || onClose ? (
          <ButtonsContainer>
            <NextPrevCloseButtons
              closeText={closeText}
              optionButtonText={optionButtonText}
              onOptionButtonClick={onOptionButtonClick}
              nextTab={next && nextTab}
              onClose={onClose}
              hasStickyNav={hasStickyNav}
              carFileDetails={carFileDetails}
              addBackground
              {...(hasChevronInNextPrevCloseButtons && rightIconWithChevronProps)}
            />
          </ButtonsContainer>
        ) : null}

        {isMobile && newBidSelectModalOpen && (
          <StyledSelectModal
            title={t('valuationIndication')}
            data-test-e2e="select-new-bids-modal"
            open={newBidSelectModalOpen}
            showCloseButton
            closeHandler={() => setNewBidSelectModalOpen(false)}
            options={bidProviders.map((bidProvider) => ({
              label: bidProvider.label,
              handler: () => getButtonClickHandler(bidProvider.button),
            }))}
          />
        )}
      </VerticalLayout>
      <MissingDataModal
        title={t('missingValuationDataModalTitle')}
        showCloseButton
        open={missingDataModalOpen}
        closeHandler={() => setMissingDataModalOpen(false)}
      >
        <Typography type="BodyParagraph">
          {t('missingValuationDataModalText')}
        </Typography>
      </MissingDataModal>
    </>
  )
}

BidsForm.propTypes = {
  className: PropTypes.object,
  bids: PropTypes.object,
  carFile: PropTypes.object.isRequired,
  closeText: PropTypes.string,
  dynamicEndpoint: PropTypes.object,
  forms: PropTypes.object.isRequired,
  getBids: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  next: PropTypes.bool,
  nextTab: PropTypes.func,
  onClose: PropTypes.func,
  openOverlay: PropTypes.func.isRequired,
  previousTab: PropTypes.func,
  previous: PropTypes.bool,
  postDynamicEndpoint: PropTypes.func.isRequired,
  updateCarfile: PropTypes.func,
  valuation: PropTypes.bool,
  optionButtonText: PropTypes.string,
  onOptionButtonClick: PropTypes.func,
  hasStickyNav: PropTypes.bool,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
  carFileDetails: PropTypes.bool,
}

BidsForm.defaultProps = {
  className: null,
  bids: null,
  dynamicEndpoint: null,
  closeText: null,
  onClose: null,
  next: null,
  nextTab: null,
  previous: null,
  previousTab: null,
  updateCarfile: () => {},
  valuation: false,
  optionButtonText: null,
  onOptionButtonClick: null,
  hasStickyNav: false,
  hasChevronInNextPrevCloseButtons: false,
  carFileDetails: false,
}

export default BidsForm
