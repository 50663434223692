import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import AuthForm from 'components/molecules/auth-form'
import { AuthFormTypeEnum } from 'config/enums'

const Container = styled.div`
  width: 100%;
`

const StyledAuthForm = styled(AuthForm)`
  box-shadow: ${({ theme }) => theme.shadows.bottom};
  background-color: ${({ theme }) => theme.colors.brandGolf};
  padding: ${({ theme }) => theme.sizings.lvl3};
`

function ForgotPasswordForm({ error, feedback, onSubmit }) {
  const { t } = useTranslation()

  const fields = [
    {
      name: 'customer',
      title: t('customerNumber'),
      validator: (values, index) =>
        values[index].length < 1 && t('customerNumberRequired'),
    },
    {
      name: 'user',
      title: t('username'),
      validator: (values, index) =>
        values[index].length < 1 && t('usernameRequired'),
    },
    {
      name: 'email',
      title: t('email'),
      type: 'email',
      validator: (values, index) =>
        values[index].length < 1 && t('emailRequired'),
    },
  ]

  return (
    <Container>
      <StyledAuthForm
        error={error}
        feedback={feedback}
        fields={fields}
        onSubmit={onSubmit}
        submitText={t('requestPassword')}
        title={t('forgotPassword')}
        formType={AuthFormTypeEnum.FORGOT_PASSWORD}
      />
    </Container>
  )
}

ForgotPasswordForm.propTypes = {
  error: PropTypes.string,
  feedback: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSubmit: PropTypes.func.isRequired,
}

ForgotPasswordForm.defaultProps = {
  error: null,
  feedback: null,
}

export default ForgotPasswordForm
