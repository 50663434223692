import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import { useMediaLayout } from 'use-media'
import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'
import Icon from 'components/atoms/icon'
import Tab from 'components/molecules/tab'
import DropdownMenu from 'components/atoms/dropdown-menu'

const TabsContainer = styled.div`
  position: relative;
`

const Container = styled.div`
  width: auto;
  display: flex;
  background: ${(props) => props.theme.colors.brandGolf};
  padding: 0 0 0 16px;
  scrollbar-width: none;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.tablet`
    background: none;
    padding: 0;
  `}
`

const ItemContainer = styled.div`
  display: flex;
  height: auto;
  min-width: auto;
  border-bottom: 1px solid ${(props) => props.theme.colors.brandDelta};

  ${media.tablet`
    border: none;
    margin: 0;
  `};
`

const SubContainer = styled.div`
  background: ${(props) => props.theme.colors.brandGolf};
  width: 100%;
  display: flex;
  align-items: flex-end;
`

const SubItemContainer = styled.div`
  width: 100%;
  display: flex;

  padding: 8px 16px 0 0;

  ${media.tablet`
    padding: 0px 24px 0 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.brandDelta};
    margin: 0 40px;
  `};
`

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
`

const LeftArrowContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.defaultBackground};
  box-shadow: ${({ theme }) => theme.colors.inactiveBackground} 8px 0px 4px -4px;
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 0;
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  position: absolute;
  top: 0;
`

const RightArrowContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.defaultBackground};
  box-shadow: ${({ theme }) => theme.colors.inactiveBackground} -8px 0px 4px -4px;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  right: 0;
  top: 0;
`

// This TabList component is used in multiple places in the app, in different ways.
// If we'd always use "to + search", we will end up with duplicated search queries (e.g. /stock?name=dave&color=yellow?name=dave&color=yellow)
// This function checks if the query params are already set by finding the first question mark in the to param.
// If the search query is already set, we're adding the new params to the original ones without duplicating.
// This patch is specifically written for when the user is navigating the tabs in the /marketview section of the application.
const generateTo = (to, search) => {
  if (to.includes('?')) {
    const url = to.split('?')[0]
    return url + search
  }

  return to + search
}
function TabList({
  activeIndex,
  history,
  location,
  items,
  small,
  bringTabInView,
  alwaysSmall,
  ...restProps
}) {
  // @TODO Wrap hooks from extarnal libraries in our own
  // custom hooks by means of a 'facade' to make refactoring
  // easy later and the reduce the risk of the fairly new
  // React functionality
  const containerRef = useRef()
  const innerContainerRef = useRef()
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const [hasLeftOverflow, setHasLeftOverflow] = useState()
  const [hasRightOverflow, setHasRightOverflow] = useState()

  useEffect(() => {
    // The refs don't exist for a small tablist so don't execute
    // this effect in that case
    const currentContainerRef = containerRef.current

    function setOverflowState() {
      const containerRect = containerRef.current.getBoundingClientRect()
      const innerContainerRect =
        innerContainerRef.current.getBoundingClientRect()

      setHasRightOverflow(innerContainerRect.right - 5 > containerRect.right)
      setHasLeftOverflow(innerContainerRect.left + 5 < containerRect.left)
    }

    if (!small) {
      setOverflowState()
      window.addEventListener('resize', setOverflowState)
      currentContainerRef.addEventListener('scroll', setOverflowState)
    }

    return () => {
      if (!small) {
        window.removeEventListener('resize', setOverflowState)
        currentContainerRef.removeEventListener('scroll', setOverflowState)
      }
    }
  }, [small])

  function handleDropDownChange(value) {
    history.push(items[value].to)
  }

  function handleScrollLeft() {
    const innerContainerChildren = innerContainerRef.current.children

    scrollTo(innerContainerChildren[0], {
      horizontal: 'start',
      vertical: 'nearest',
    })
  }

  function handleScrollRight() {
    const innerContainerChildren = innerContainerRef.current.children

    scrollTo(innerContainerChildren[innerContainerChildren.length - 1], {
      horizontal: 'end',
      vertical: 'nearest',
    })
  }

  if (!small || alwaysSmall) {
    return (
      <TabsContainer {...restProps}>
        {hasLeftOverflow && (
          <LeftArrowContainer onClick={handleScrollLeft}>
            <Icon size="md" type="arrowsLeft" />
          </LeftArrowContainer>
        )}
        <Container className="Container" ref={containerRef}>
          <ItemContainer className="ItemContainer" ref={innerContainerRef}>
            {items.map((item, index) => (
              <Tab
                count={item.count}
                disabled={item.disabled}
                selected={index === activeIndex}
                key={index.toString()}
                label={item.label}
                to={generateTo(item.to, location.search)}
                small={alwaysSmall || isMobile}
                bringInView={false}
              />
            ))}
          </ItemContainer>
        </Container>
        {hasRightOverflow && (
          <RightArrowContainer onClick={handleScrollRight}>
            <Icon size="md" type="arrowsRight" />
          </RightArrowContainer>
        )}
      </TabsContainer>
    )
  }
  return (
    <TabsContainer {...restProps}>
      <Container className="Container" data-element="tab-list-container">
        <SubContainer
          className="SubContainer"
          data-element="tab-list-subcontainer"
        >
          <SubItemContainer
            className="SubItemContainer"
            data-element="tab-list-sub-item-container"
          >
            {isMobile ? (
              <StyledDropdownMenu
                items={items}
                onChange={handleDropDownChange}
                selected={activeIndex}
              />
            ) : (
              <>
                {items.map((item, index) => (
                  <Tab
                    key={index.toString()}
                    disabled={item.disabled}
                    index={index.toString()}
                    selected={index === activeIndex}
                    label={item.label}
                    to={item.to}
                    small={small}
                    bringInView={bringTabInView}
                    count={item.count}
                  />
                ))}
              </>
            )}
          </SubItemContainer>
        </SubContainer>
      </Container>
    </TabsContainer>
  )
}

TabList.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
      count: PropTypes.number,
    }),
  ).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  small: PropTypes.bool, // If true, the tabs will be rendered with underlines, and will be rendered as a dropdown on mobile
  alwaysSmall: PropTypes.bool, // If true, the tabs will be rendered with underlines and overflow will be handled with left/right arrows
  bringTabInView: PropTypes.bool,
}

TabList.defaultProps = {
  small: false,
  alwaysSmall: false,
  bringTabInView: false,
}

export default withRouter(TabList)
