import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { reset } from 'redux-form'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'

import { clearData } from 'redux/actions/data'

import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import CarfileHeader from 'components/molecules/carfile-header'
import CarfileData from 'components/organisms/carfile-data'
import CarfileEnrichmentDialog from 'components/molecules/carfile-enrichment-dialog'
import GeneralDataError from 'components/organisms/general-data-error'
import CarEntryHeader from 'components/molecules/car-entry-header'

const Container = styled.div`
  flex-direction: column;

  ${media.desktop`
    align-items: flex-start;
    display: flex;
    padding: 0 60px;
  `}
`

const StyledCarfileHeader = styled(CarfileHeader)`
  display: none;

  ${media.desktop`
    background: ${({ theme }) => theme.colors.brandFoxtrot};
    display: flex;
    position: sticky;
    top: 0;
    z-index: 11;
  `}
`
const StyledCarEntryHeader = styled(CarEntryHeader)`
  display: none;

  ${media.desktop`
    display: flex;
    background-color: ${({ theme }) => theme.colors.sectionBackground};
    width: 100%;
    height: ${({ theme }) => theme.sizeByFactor(14)};
    position: sticky;
    top: ${({ theme }) => theme.sizeByFactor(8)};
    z-index: 11;
    border-bottom: 22px solid ${({ theme }) => theme.colors.brandFoxtrot};
    line-height: 16px;

    [data-test-e2e="type"] {
      font-size: ${({ theme }) => theme.sizings.lvl3};
      font-weight: 300;
      margin: 0;
    }

    [data-test-e2e="brand"],
    [data-test-e2e="model"] {
      display: inline-block;
      margin-right: 5px;
      font-size: ${({ theme }) => theme.sizings.lvl3};
      color: ${({ theme }) => theme.colors.text};
      font-weight: 600;
      padding-bottom: ${({ theme }) => theme.sizeByFactor(1.5)};
    }
  `}
`

function CarFile({
  userDetails,
  addCarAssets,
  carAssets,
  carAssetsSort,
  carfile,
  carKind,
  deleteCarAssets,
  forms,
  getCarfile,
  getCarAssets,
  getForms,
  getBids,
  history,
  match,
  openImageOverlay,
  openOverlay,
  rotateImages,
  salesView,
  setCarKind,
  setCarStatus,
  sortCarAssets,
  toggleSalesView,
  updateCarfile,
}) {
  const { t } = useTranslation()
  const carStatus = carfile.data && carfile.data.status
  const carId = match.params.id
  const dispatch = useDispatch()

  useEffect(() => {
    getCarfile(carId)
    getCarAssets(carId)
    getForms('carfile', { auto_id: carId })
    // retrieve rdw form (like in the ActionBar), but with the carFile id as parameter:
    // so that the car file data is prefilled:
    dispatch(reset('rdw'))
    getForms('rdw', { auto_id: carId })
    getForms('cleanup', { auto_id: carId })
    getForms('transport', { auto_id: carId })
  }, [carId, dispatch, getCarAssets, getCarfile, getForms, match.params.id])

  useEffect(() => {
    // When carStatus changes, other assets can come available,
    // like the default 'verwacht' (expected) foto. when a car
    // changes from 'lopend' (lead) to 'verwacht' (expected).
    getCarAssets(carId)
  }, [carStatus, carId, getCarAssets])

  useEffect(() => {
    const { hash } = history.location
    if (hash && forms && !forms.loading && carfile && !carfile.loading) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        setTimeout(() => {
          scrollTo(element)
        }, 100)
      }
    }
  }, [history, forms, carfile])

  useEffect(() => {
    return () => {
      /**
       * Clear the carfile data from the Redux store when the user navigates away from the carfile.
       * This is required to make the RDW menu item work properly, since it uses the carfile ID from
       * the Redux store. If the data is not cleared, the link to the RDW screens is filled with the
       * carfile ID that was opened last. Since the carfile data is fetched every time this
       * component mounts, regardless of what is in the Redux store, there is no need to keep the
       * data around.
       **/
      dispatch(clearData('carfile'))
    }
  }, [dispatch])

  if (!carfile.data && !carfile.loading) {
    return <GeneralDataError error={t('errorRetrievingCar')} />
  }

  const carPicture =
    carAssets?.data?.find(
      (picture) =>
        picture?.position === 1 && picture?.category === 'commercieel',
    )?.src || undefined

  const carDocuments =
    carAssets &&
    carAssets.data &&
    carAssets.data.filter(({ type }) => type === 'document')

  const {
    title: popupTitle,
    message: popupMessage,
    ok_action: popupAction,
    closable: popupClosable,
  } = carfile?.data?.popup || {}
  const carfileEnrichmentDialogInitiallyOpen =
    !!popupTitle && !!popupMessage && !!popupAction

  const { brand, model, type, kind, location, chassisnr, kenteken } = carfile.data || {}

  return (
    <AppLayoutContainer pageTitle={t('carFile')}>
      <Container>
        <StyledCarfileHeader
          carfile={carfile && carfile.data}
          loading={carfile.loading}
          salesView={salesView}
          toggleSalesView={toggleSalesView}
        />
        <StyledCarEntryHeader
          imagePath={carPicture}
          brand={brand}
          model={model}
          type={type}
          licensePlate={kenteken}
          vin={chassisnr}
          carId={carId}
          carKind={kind}
          setCarKind={setCarKind}
          location={location}
          carDetailsPreview
        />
        <CarfileData
          addCarAssets={addCarAssets}
          carAssetsSort={carAssetsSort}
          deleteCarAssets={deleteCarAssets}
          documents={carDocuments}
          carFile={carfile && carfile.data}
          carKind={carKind && carKind.data}
          carId={carId}
          forms={forms && forms.data}
          getForms={getForms}
          getBids={getBids}
          id="data"
          loading={!forms || forms.loading || !carfile || carfile.loading}
          rotateImages={rotateImages}
          sortCarAssets={sortCarAssets}
          status={carfile.status}
          updateCarfile={updateCarfile}
          data-test-e2e="car-file-data"
          userDetails={userDetails}
          carAssets={carAssets}
          openOverlay={openOverlay}
          openImageOverlay={openImageOverlay}
          setCarKind={setCarKind}
          salesView={salesView}
          toggleSalesView={toggleSalesView}
          setCarStatus={setCarStatus}
          carStatus={carStatus}
          history={history}
          carfileSummary={carfile}
        />
        {carfileEnrichmentDialogInitiallyOpen && (
          <CarfileEnrichmentDialog
            title={popupTitle}
            message={popupMessage}
            action={popupAction}
            closable={!!popupClosable}
          />
        )}
      </Container>
    </AppLayoutContainer>
  )
}

CarFile.propTypes = {
  userDetails: PropTypes.object.isRequired,
  addCarAssets: PropTypes.func.isRequired,
  carAssets: PropTypes.object,
  carAssetsSort: PropTypes.object,
  carfile: PropTypes.object,
  carKind: PropTypes.object,
  deleteCarAssets: PropTypes.func.isRequired,
  forms: PropTypes.object,
  getCarfile: PropTypes.func.isRequired,
  getCarAssets: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  getBids: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  openOverlay: PropTypes.func.isRequired,
  openImageOverlay: PropTypes.func.isRequired,
  rotateImages: PropTypes.func.isRequired,
  salesView: PropTypes.bool.isRequired,
  setCarKind: PropTypes.func.isRequired,
  setCarStatus: PropTypes.func.isRequired,
  sortCarAssets: PropTypes.func.isRequired,
  toggleSalesView: PropTypes.func.isRequired,
  updateCarfile: PropTypes.func.isRequired,
}

CarFile.defaultProps = {
  carAssets: null,
  carAssetsSort: null,
  carfile: {},
  carKind: {},
  forms: null,
}

export default CarFile
