import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useLocation, withRouter } from 'react-router-dom'

import Tabs from 'components/layouts/tabs'
import SalesOutsideDistrict from 'components/views/market-view/sales-outside-district'
import MarketviewStock from 'components/views/market-view/marketview-stock'
import MarketviewStaytime from 'components/views/market-view/marketview-staytime'
import MarketviewPricing from 'components/views/market-view/marketview-pricecheck'

const Container = styled.div`
  display: grid;
  min-height: 200px;
  width: 100%;
`
/**
 * Tabs component for rendering the tabs with their respective routes of the subjects
 */
function MarktviewTabs({ match }) {
  const { t } = useTranslation()
  const { search } = useLocation()
  const tabItems = []

  tabItems.push({
    label: t('salesOutsideRegion'),
    to: `${match.url !== '/' ? `${match.url}/` : ''}sales${search}`,
    matchTo: `${match.url !== '/' ? `${match.url}/` : '/'}sales`,
    component: SalesOutsideDistrict,
  })

  tabItems.push({
    label: t('stock'),
    to: `${match.url !== '/' ? `${match.url}/` : ''}stock${search}`,
    matchTo: `${match.url !== '/' ? `${match.url}/` : '/'}stock`,
    component: MarketviewStock,
  })

  tabItems.push({
    label: t('standingTime'),
    to: `${match.url !== '/' ? `${match.url}/` : ''}standing-time${search}`,
    matchTo: `${match.url !== '/' ? `${match.url}/` : '/'}standing-time`,
    component: MarketviewStaytime,
  })

  tabItems.push({
    label: t('priceCheck'),
    to: `${match.url !== '/' ? `${match.url}/` : ''}pricing${search}`,
    matchTo: `${match.url !== '/' ? `${match.url}/` : '/'}pricing`,
    component: MarketviewPricing,
  })

  return (
    <Container>
      <Tabs items={tabItems}>
        {tabItems.map((item, index) =>
          item.subItems ? (
            <Tabs items={item.subItems} key={index.toString()} small>
              {item.subItems.map((subItem, subIdx) => (
                <subItem.component key={subIdx.toString()} />
              ))}
            </Tabs>
          ) : (
            <item.component key={index.toString()} />
          ),
        )}
      </Tabs>
    </Container>
  )
}

MarktviewTabs.propTypes = {
  /** Match object contains information about how a <Route path> matched the URL. [Documentation](https://reacttraining.com/react-router/web/api/match) */
  match: PropTypes.object.isRequired,
}

MarktviewTabs.defaultProps = {}

export default withRouter(MarktviewTabs)
