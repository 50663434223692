import NextPrevCloseButtons from 'components/molecules/next-prev-close-buttons'
import MediaForm, {
  propTypes as mediaFormPropTypes,
} from 'components/organisms/media-form'
import PropTypes from 'prop-types'
import React from 'react'
import { media } from 'utilities/styled'
import styled from 'styled-components'

const StyledMediaForm = styled(MediaForm)`
  ${media.desktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.sizings.lvl2};
  `}
`

function CarAssetsForm({
  addCarAssets,
  carAssetsSort,
  carFile,
  closeText,
  onClose,
  deleteCarAssets,
  items,
  level,
  next,
  nextTab,
  previous,
  previousTab,
  rotateImages,
  sortCarAssets,
  type,
  allowImageAsDocumentUpload,
  itemsFilter,
  itemsFilterValue,
  customOptions,
  carFileDetails,
  ...restProps
}) {
  const carId = carFile?.id || carFile?.auto_id

  function handleUpload(formData) {
    const isDocument = type === 'documents' && !!allowImageAsDocumentUpload
    addCarAssets(formData, carId, isDocument)
  }

  function handleDelete(selectedImageIds) {
    deleteCarAssets(carId, selectedImageIds, type)
  }

  function handleSort(newSortOrderData) {
    sortCarAssets(carId, newSortOrderData)
  }

  function handleRotate(imageIds, degrees = 'R90') {
    rotateImages({
      auto_id: carFile.id || carFile.auto_id,
      imageIds,
      deg: degrees,
    })
  }

  return (
    <>
      <StyledMediaForm
        carAssetsSort={carAssetsSort}
        carId={carId}
        items={items}
        onUpload={handleUpload}
        onDelete={handleDelete}
        onRotate={handleRotate}
        onSort={handleSort}
        level={level || 'cta'}
        type={type}
        allowImageAsDocumentUpload={allowImageAsDocumentUpload}
        itemsFilter={itemsFilter}
        itemsFilterValue={itemsFilterValue}
        customOptions={customOptions}
        {...restProps}
      />
      {next || previous || onClose ? (
        <NextPrevCloseButtons
          closeText={closeText}
          previousTab={previous && previousTab}
          nextTab={next && nextTab}
          onClose={onClose}
          carFileDetails={carFileDetails}
          addBackground
        />
      ) : null}
    </>
  )
}

const {
  allowImageAsDocumentUpload,
  itemsFilter,
  itemsFilterValue,
  customOptions,
} = mediaFormPropTypes

export const propTypes = {
  addCarAssets: PropTypes.func,
  carAssetsSort: PropTypes.object,
  carFile: PropTypes.object,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
  deleteCarAssets: PropTypes.func,
  items: PropTypes.array,
  level: PropTypes.string,
  next: PropTypes.bool,
  nextTab: PropTypes.func,
  previous: PropTypes.bool,
  previousTab: PropTypes.func,
  rotateImages: PropTypes.func,
  sortCarAssets: PropTypes.func,
  type: PropTypes.oneOf(['images', 'documents']).isRequired,
  allowImageAsDocumentUpload,
  itemsFilter,
  itemsFilterValue,
  customOptions,
  carFileDetails: PropTypes.bool,
}

CarAssetsForm.propTypes = propTypes

CarAssetsForm.defaultProps = {
  addCarAssets: null,
  deleteCarAssets: null,
  sortCarAssets: null,
  carAssetsSort: null,
  carFile: null,
  closeText: null,
  level: null,
  next: null,
  nextTab: null,
  previous: null,
  previousTab: null,
  onClose: null,
  rotateImages: null,
  items: null,
  allowImageAsDocumentUpload: MediaForm.defaultProps.allowImageAsDocumentUpload,
  itemsFilter: MediaForm.defaultProps.itemsFilter,
  itemsFilterValue: MediaForm.defaultProps.itemsFilterValue,
  customOptions: MediaForm.defaultProps.customOptions,
  carFileDetails: false
}

export default CarAssetsForm
