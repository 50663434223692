import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import LoadingButton from 'components/atoms/loading-button'
import { propTypes as iconPropTypes } from 'components/atoms/icon'

import { media } from 'utilities/styled'

const StyledContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  position: ${({ $hasStickyNav }) => ($hasStickyNav ? 'fixed' : undefined)};
  left: ${({ $hasStickyNav }) => ($hasStickyNav ? '0' : undefined)};
  bottom: ${({ $hasStickyNav }) => ($hasStickyNav ? '0' : undefined)};
  position: ${({ $carFileDetails }) => ($carFileDetails && 'sticky')};
  bottom: ${({ theme, $carFileDetails, $addBackground }) => ($carFileDetails && ($addBackground ? '0' : theme.sizeByFactor(2.7)))};;
  background: ${({ theme, $addBackground }) => $addBackground && theme.colors.brandGolf};
  padding: ${({ theme, $addBackground }) => $addBackground && theme.sizings.lvl2} 0;

  ${media.tablet`
    padding-left: ${({ $hasStickyNav }) => ($hasStickyNav ? '20px' : undefined)};
    padding-right: ${({ $hasStickyNav }) => ($hasStickyNav ? '20px' : undefined)};
  `}

  ${media.desktop`
    padding-left: ${({ $hasStickyNav }) => ($hasStickyNav ? '60px' : undefined)};
    padding-right: ${({ $hasStickyNav }) => ($hasStickyNav ? '60px' : undefined)};
    left: ${({ theme, $hasStickyNav }) => ($hasStickyNav ? theme.widths.navigationBarWidth : undefined)};
    width: ${({ theme, $hasStickyNav }) => ($hasStickyNav ? `calc(100% - ${theme.widths.navigationBarWidth} - ${theme.widths.actionBarWidth})` : '100%')};
  `}
`

const ButtonsContainer = styled.div`
  background-color: ${({ $hasStickyNav }) => ($hasStickyNav ? 'white' : 'transparent')}}};
  box-shadow: ${({ theme, $hasStickyNav }) => ($hasStickyNav ? theme.shadows.top : undefined)}}};
  padding: ${({ theme, $hasStickyNav }) => ($hasStickyNav ? `${theme.sizings.lvl2} 20px` : undefined)};
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: ${({ $hasOneButton }) => ($hasOneButton ? 'flex-end' : 'space-between')};
  width: 100%;
  
  & > *:last-child {
    margin-top: ${({ theme, $hasStickyNav, $hasOneButton }) => ($hasStickyNav || !$hasOneButton ? undefined : theme.sizings.lvl2)};
    margin-left: ${({ theme, $hasOneButton }) => !$hasOneButton && theme.sizings.lvl1};
  }

  ${media.tablet`
    justify-content: ${({ $hasOptionButton, $hasOneButton }) => ($hasOneButton || !$hasOptionButton ? 'flex-end' : 'space-between')};

    & > *:last-child {
      margin-left: ${({ theme, $hasOneButton }) => !$hasOneButton && theme.sizings.lvl3};
      margin-top: 0;
    }
  `}

  ${media.desktop`
    padding: ${({ theme, $hasStickyNav }) => ($hasStickyNav ? `${theme.sizings.lvl2} 32px` : undefined)};

    & > *:last-child {
      margin-top: 0;
    }
  `}
`

const OptionButton = styled(LoadingButton)`
  text-transform: uppercase;
  padding-left: 0;
`

const StyledButton = styled(LoadingButton)`
  width: ${({ $hasOneButton }) => ($hasOneButton ? '100%' : 'auto')};
  min-width: ${({ theme }) => theme.sizeByFactor(16)};

  ${media.phone`
    width: auto;
    margin-top: 0;
  `}

  ${({ $hasRightChevronIcon }) =>
    $hasRightChevronIcon
      ? `
    > span:last-child:after {
      content: '';
      position: absolute;
      left: 79.79%;
      right: 10.96%;
      top: 67.76%;
      bottom: 8.55%;
      background: #FFFFFF;
      transform: rotate(-90deg);
    }
  `
      : undefined}
`

export const rightIconWithChevronProps = {
  rightIcon: 'chevronRight',
  rightIconColor: 'sectionBackground',
  rightIconSize: 'md',
}

function NextPrevCloseButtons({
  className,
  closeText,
  nextTab,
  onClose,
  previousTab,
  optionButtonText,
  optionButtonDisabled,
  onOptionButtonClick,
  hasStickyNav,
  carFileDetails,
  disableNextTabButton,
  disableCloseButton,
  rightIcon,
  rightIconColor,
  rightIconSize,
  addBackground,
  ...restProps
}) {
  const mounted = useRef(false)
  const { t } = useTranslation()
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
  const [isOptionButtonLoading, setIsOptionButtonLoading] = useState(false)

  const buttonCount = [
    previousTab,
    nextTab,
    onOptionButtonClick,
    onClose,
  ].filter((buttonMethod) => buttonMethod).length
  const hasOneButton = buttonCount === 1
  const hasOptionButton = optionButtonText && !!onOptionButtonClick

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }
    return () => {
      if (mounted.current) {
        mounted.current = false
      }
    }
  }, [])

  return (
    <StyledContainer
      $hasStickyNav={hasStickyNav}
      $carFileDetails={carFileDetails}
      $addBackground={addBackground}
      data-test-e2e="next-prev-close-buttons"
      {...restProps}
    >
      <ButtonsContainer
        className={className}
        $hasStickyNav={hasStickyNav}
        $hasOneButton={hasOneButton}
        $hasOptionButton={hasOptionButton}
      >
        {previousTab && (
          <StyledButton
            $hasOneButton={hasOneButton}
            onClick={previousTab}
            text={t('previous')}
            data-test-e2e="previous"
          />
        )}
        {optionButtonText && onOptionButtonClick && (
          <OptionButton
            disabled={optionButtonDisabled || isSubmitButtonLoading}
            level="option"
            text={optionButtonText}
            onClick={async (e) => {
              setIsOptionButtonLoading(true)
              await onOptionButtonClick(e)
              if (mounted.current) {
                setIsOptionButtonLoading(false)
              }
            }}
            isLoading={isOptionButtonLoading}
            data-test-e2e="option"
          />
        )}
        {nextTab && (
          <StyledButton
            disabled={disableNextTabButton}
            $hasOneButton={hasOneButton}
            level="cta"
            onClick={nextTab}
            text={t('next')}
            data-test-e2e="next"
            rightIcon={rightIcon}
            rightIconColor={rightIconColor}
            rightIconSize={rightIconSize}
          />
        )}
        {onClose && (
          <StyledButton
            disabled={disableCloseButton || isOptionButtonLoading}
            $hasOneButton={hasOneButton}
            level="cta"
            onClick={async (e) => {
              setIsSubmitButtonLoading(true)
              await onClose(e)

              if (mounted.current) {
                setIsSubmitButtonLoading(false)
              }
            }}
            isLoading={isSubmitButtonLoading}
            text={closeText || t('saveAndClose')}
            data-test-e2e="close"
            rightIcon={rightIcon}
            rightIconColor={rightIconColor}
            rightIconSize={rightIconSize}
          />
        )}
      </ButtonsContainer>
    </StyledContainer>
  )
}

export const nextPrevCloseButtonsPropTypes = {
  className: PropTypes.string,
  closeText: PropTypes.string,
  nextTab: PropTypes.func,
  onClose: PropTypes.func,
  previousTab: PropTypes.func,
  optionButtonText: PropTypes.string,
  optionButtonDisabled: PropTypes.bool,
  onOptionButtonClick: PropTypes.func,
  hasStickyNav: PropTypes.bool,
  carFileDetails: PropTypes.bool,
  addBackground: PropTypes.bool,
  disableNextTabButton: PropTypes.bool,
  disableCloseButton: PropTypes.bool,
  rightIcon: PropTypes.string,
  rightIconColor: PropTypes.string,
  rightIconSize: iconPropTypes.size,
}

NextPrevCloseButtons.propTypes = nextPrevCloseButtonsPropTypes

NextPrevCloseButtons.defaultProps = {
  className: null,
  closeText: null,
  nextTab: null,
  onClose: null,
  previousTab: null,
  optionButtonText: null,
  optionButtonDisabled: false,
  onOptionButtonClick: null,
  hasStickyNav: false,
  carFileDetails: false,
  addBackground: false,
  disableNextTabButton: false,
  disableCloseButton: false,
  rightIcon: null,
  rightIconColor: null,
  rightIconSize: null,
}

export default NextPrevCloseButtons
