import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import { getFormValues, reduxForm, isValid } from 'redux-form'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'

import {
  getFlatFields,
  getValue,
  isFieldRequired,
  validateFields,
} from 'utilities/form-utils'
import { scrollTo, scrollToTop } from 'utilities/utils'
import { media } from 'utilities/styled'
import { toKebabCase } from 'utilities/format'

import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'
import NextPrevCloseButtons, {
  rightIconWithChevronProps,
} from 'components/molecules/next-prev-close-buttons'
import FieldSet from 'components/molecules/field-set'
import NewExpandableRow from 'components/molecules/new-expandable-row'

import { VALUATION } from 'config/routes'

const Form = styled.form`
  width: 100%;
`

const Container = styled.div`
  ${({ $carFileDetails, theme }) =>
    $carFileDetails &&
    css`
      width: 100%;
      background-color: ${theme.colors.defaultBackground};
      z-index: 12;
      position: sticky;
      bottom: 0;
      padding: ${({ theme }) => theme.sizings.lvl2} 0;
    `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.phone`
    flex-direction: row;
  `}
`

const CancelButton = styled(Button)`
  margin-right: auto;
`

function getDefaultValues(values, props) {
  const fields = getFlatFields(props.fieldsets)
  const processedValues = fields.reduce(
    (result, field) => ({
      ...result,
      [field.name]: getValue(values, field),
    }),
    {},
  )
  return processedValues
}

/**
 * Find out if a field is (conditionally) hidden or not.
 * @param {Object} field
 * @param {Object} currentValues
 */
function getIsHidden(field, currentValues) {
  if (!field.hidden) {
    return false
  }

  return field.hidden.reduce((isHidden, conditional) => {
    if (conditional.operator !== 'equals') {
      console.error(
        "There is a required condition operator defined in a form field which the frontend can't handle at the moment:",
        conditional.operator,
      )
      return isHidden
    }

    return isHidden || currentValues[conditional.field] === conditional.values
  }, false)
}

function validate(values, formProps) {
  const fields = getFlatFields(formProps.fieldsets)

  return validateFields(values, fields)
}

/**
 * The ComplexForm can take a form configuration and generate a fully functioning form from it.
 *
 * ## Some key special features are:
 *
 * ### conditional content
 *
 * content can be conditionally shown based on whether or not certain fields have a certain value.
 * ```js
 * {
    "endpoint": "\/cl\/v3\/marktplaats\/koppelverwerken",
    "fieldsets": [
      {
        "title": "Marktplaats koppelen",
        "description": "Er is nog geen Marktplaats account gekoppeld.",
        "fields": [
          {
            "name": "keuzeaccount",
            "title": "Keuze",
            "hint": "Kies 'bestaand' als u al een Marktplaats account heeft, anders kies 'nieuw'\t",
            "explanation": "Uw account is nog niet gekoppeld aan Marktplaats",
            "value": "bestaand",
            "required": true,
            "type": "select",
            "options": [
              {
                "label": "Nieuw",
                "value": "nieuw"
              },
              {
                "label": "Bestaand",
                "value": "bestaand"
              }
            ]
          },
          {
            "name": "bestaandaccount",
            "title": "Bestaand",
            "value": "Klik hier om een bestaand Marktplaats account te koppelen.",
            "hidden": [ // conditionally hidden entity, shown on condition below:
              {
                "field": "keuzeaccount",
                "operator": "equals",
                "values": "nieuw"
              }
            ],
            "type": "link",
            "href": "https:\/\/test-3.uccapp.nl\/ucc2.0\/v3\/marktplaats\/koppelen?"
          },
          {
            "name": "test",
            "value": "Klik op 'Wijziging opslaan' om een nieuw account aan te maken.",
            "hidden": [ // conditionally hidden entity, shown on condition below:
              {
                "field": "keuzeaccount",
                "operator": "equals",
                "values": "bestaand"
              }
            ],
            "type": "info"
          }
        ]
      }
    ],
    "localization": false
  }
 * ```
 *
 * ### conditionally required fields
 *
 * In some cases a field should only be required when another is set to
 * true (usually a checkbox). This configuration can be passed as such:
 *
 * ```
 * {
    "endpoint": "\/cl\/v3\/transport\/email",
    "reload": true,
    "fieldsets": [
      {
        "title": "Stuur transport E-mail",
        "maxColumns": 1,
        "fields": [
          {
            "name": "verstuuremail",
            "title": "Stuur E-mail",
            "variety": "floatingLabel",
            "type": "checkbox"
          },
          {
            "name": "transport_id",
            "title": "Selecteer bedrijf",
            "required": [
              {
                "field": "verstuuremail",
                "operator": "equals",
                "values": [
                  true,
                  1
                ]
              }
            ],
            "type": "select",
            "options": [
              {
                "label": "Geen",
                "value": ""
              },
              {
                "label": "Auto omzetten filiaal",
                "value": 1
              },
              {
                "label": "Nieuwbedrijf",
                "value": 36
              }
            ]
          }
        ]
      },
    ],
    "localization": true
  }
 * ```

  ### linked select fields:

  Allows one select field to repopulate another select fields based on a selected value.

  ```
    {
    "success": true,
    "total": 3,
    "data": {
      "endpoint": "\/cl\/v3\/b2bprospect\/updateinteresse",
      "fieldsets": [
        {
          "title": "Vul hier je voorkeuren in",
          "maxColumns": 2,
          "fields": [
            ...
            {
              "name": "merk",
              "title": "Merk",
              "confirm_edit": false,
              "type": "select",
              "options": [
                ... // preloaded with 'merk' options
              ]
            },
            {
              "name": "modelserie",
              "title": "Model",
              "confirm_edit": false,
              "type": "select",
              "options": [] // is leeg
              "updateBasedOn": "merk", // name of 'merk' field
              "updateEndpoint": "/.../.../.../..." // which endpoint needs to be called for a modelserie update
            },
            ...
            }
          ]
        }
      ],
      "localization": true
    }
  }
  ```

 *
 * ### contextual sub-forms in overlays
 *
 * It is possible to load a sub form as part of a regular form.
 * This sub-form is loaded in a overlay. It is bound to it's 'parent'
 * by a shared `storeFormId`.
 *
 * Please refer to the [FormField component documentation](/#/molecules/FormField) for examples on how fields based on field types
 * are rendered.
 */
function ComplexForm({
  className,
  cancelText,
  closeText,
  data,
  feedback,
  fieldsets,
  formId,
  finish,
  handleSubmit: reduxFormSubmit,
  initiallyReadOnly,
  invalid,
  level,
  dontSubmitOnPrevious,
  next,
  nextTab,
  onCancel,
  onClose,
  onSubmit,
  openOverlay,
  scrollToTopOnError,
  previous,
  previousTab,
  pristine,
  submitText,
  submitting,
  singleColumn,
  values,
  optionButtonText,
  onOptionButtonClick,
  isLoading,
  hasStickyNav,
  hasChevronInNextPrevCloseButtons,
  carFileDetails,
  ...restProps
}) {
  const match = useRouteMatch()
  const formValid = useSelector(
    (state) => state?.form?.[formId] && isValid(formId)(state),
  )
  const formRef = useRef()
  const { t } = useTranslation()
  const nextPrev = next || previous

  const [customSelectOptions, setCustomSelectOptions] = useState({})
  const [fieldToUpdateOnFieldUpdate, setFieldToUpdateOnFieldUpdate] = useState(
    {},
  )

  useEffect(() => {
    const newCustomSelectOptions = {}
    const newFieldsToUpdateOnFieldUpdate = {}
    fieldsets.forEach((fieldset) => {
      if (Array.isArray(fieldset.fields)) {
        fieldset.fields
          .filter((field) => field.type === 'select' && field.updateBasedOn)
          .forEach((field) => {
            newCustomSelectOptions[field.name] = []
            newFieldsToUpdateOnFieldUpdate[field.updateBasedOn] = {
              name: field.name,
              updateEndPoint: field.updateEndPoint,
            }
          })
      }
    })
    setCustomSelectOptions({
      ...newCustomSelectOptions,
    })
    setFieldToUpdateOnFieldUpdate({
      ...newFieldsToUpdateOnFieldUpdate,
    })
  }, [fieldsets])

  /**
   * Simplified way to set custom options for a select field.
   * @param {string} fieldName
   * @param {Array} options
   */
  const updateCustomOptions = (fieldName, options) => {
    setCustomSelectOptions({
      ...customSelectOptions,
      [fieldName]: options,
    })
  }

  function handleTopScroll() {
    if (scrollToTopOnError) {
      scrollToTop()
    } else {
      scrollTo(formRef.current)
    }
    return false
  }

  // The finish argument can be supplied to pass to the received onSubmit.
  // This is used to inform the CarEntryTabs the user is done submitting
  // and can be redirect to the carfile it has just entered.
  function submit(vals) {
    // The finish argument is used in car-bpm-declaration-step-2.js and car-entry-valuation-step2.js
    const finishParsed = !!finish

    // Return the return value of the passed onSubmit which can
    // be used to verify the submit succeeded without any issues
    return onSubmit(vals, finishParsed)
  }

  async function handleSubmit() {
    const submitted = await reduxFormSubmit(submit)()

    if (submitted !== true) {
      return handleTopScroll()
    }

    return submitted
  }

  async function handleNext() {
    const submitted = await handleSubmit()

    if (submitted === true && nextTab) {
      return nextTab()
    }

    return submitted
  }

  async function handlePrevious() {
    if (dontSubmitOnPrevious) {
      return previousTab()
    }

    const submitted = await handleSubmit()

    if (submitted === true && previousTab) {
      return previousTab()
    }

    return submitted
  }

  function handleClose() {
    const submitted = handleSubmit()

    if (submitted && onClose) {
      return onClose()
    }

    return null
  }

  function handleClickOverlay({ form, label }) {
    const translatedLabel = t(label)

    return openOverlay('form', {
      data,
      formCategory: form.category,
      formId: form.id,
      storeFormId: formId,
      icon: 'client',
      onSubmit: () => {},
      resetOnsubmit: false,
      submitText: translatedLabel,
      title: translatedLabel,
      /**
       * This means that any form-in-a-overlay triggered from inside a ComplexForm
       * will NOT reset it's contents when simply closed, but this is fine, since
       * these types of of forms currently are only used to enhance content of the parent form,
       * like a 'sub-form'.
       */
      doNotResetOnClose: false,
    })
  }

  const processField = (field, fieldValueIndex) => {
    const value =
      values[field.name] !== null && moment.isMoment(values[field.name])
        ? moment(values[field.name]).format('YYYY-MM-DD')
        : values[field.name]

    return {
      ...field,
      value,
      type: getIsHidden(field, values) ? 'hidden' : field.type,
      required: isFieldRequired(field, values),
      fieldValueIndex,
    }
  }

  // TODO: Make this function recursive
  const processedFieldSet = fieldsets.map((fieldSet, fieldSetIndex) => {
    const recursiveArrayLength = fieldsets
      .slice(0, fieldSetIndex)
      .flatMap((obj) => obj.fields).length

    return {
      ...fieldSet,
      type: getIsHidden(fieldSet, values) ? 'hidden' : fieldsets.type,
      fields: fieldSet.fields
        ? fieldSet.fields.map((field, fieldIndex) =>
            processField(field, fieldIndex + recursiveArrayLength),
          )
        : [],
      fieldsets: fieldSet.fieldsets
        ? fieldSet.fieldsets.map((nestedFieldSet, nestedFieldSetIndex) => {
            const nestedRecursiveArrayLength = fieldSet.fieldsets
              .slice(0, nestedFieldSetIndex)
              .flatMap((obj) => obj.fields).length

            return {
              ...nestedFieldSet,
              fields: nestedFieldSet.fields
                ? nestedFieldSet.fields.map((field, nestedFieldIndex) =>
                    processField(
                      field,
                      recursiveArrayLength +
                        nestedFieldIndex +
                        nestedRecursiveArrayLength,
                    ),
                  )
                : [],
            }
          })
        : [],
    }
  })

  const renderFieldSet = (fieldSet, fieldSetIndex, nested) => {
    if (fieldSet.fieldsets && fieldSet.fieldsets.length > 0) {
      return fieldSet.groupedFieldSet ? (
        <NewExpandableRow
          isInitiallyExpanded={fieldSet.isInitiallyExpanded}
          title={fieldSet.title}
          key={`fieldSetGroup_${fieldSet.title}_${fieldSetIndex}`}
          data-test-e2e={`fieldSetGroup_${fieldSet.title}`}
        >
          {fieldSet.fieldsets.map((nestedFieldSet, nestedFieldSetIndex) =>
            renderFieldSet(
              nestedFieldSet,
              fieldSetIndex + nestedFieldSetIndex,
              true,
            ),
          )}
        </NewExpandableRow>
      ) : (
        fieldSet.fieldsets.map((nestedFieldSet, nestedFieldSetIndex) =>
          renderFieldSet(
            nestedFieldSet,
            fieldSetIndex + nestedFieldSetIndex,
            true,
          ),
        )
      )
    }
    return (
      <FieldSet
        key={`fieldSet_${fieldSet.title}_${fieldSetIndex}`}
        {...fieldSet}
        initiallyReadOnly={initiallyReadOnly}
        index={fieldSetIndex}
        values={values}
        customSelectOptions={customSelectOptions}
        updateCustomOptions={updateCustomOptions}
        fieldToUpdateOnFieldUpdate={fieldToUpdateOnFieldUpdate}
        singleColumn={singleColumn}
        handleClickOverlay={handleClickOverlay}
        openOverlay={openOverlay}
        nested={nested}
        data-test-e2e={
          fieldSet.title ? `fieldset-${toKebabCase(fieldSet.title)}` : undefined
        }
      />
    )
  }

  // Only take the data-test-e2e prop, because passing all the restProps gives issues
  const { 'data-test-e2e': dataTestE2e } = restProps

  return (
    <>
      {!feedback && (
          <Form
            className={className}
            onSubmit={reduxFormSubmit(submit)}
            ref={formRef}
            noValidate
            data-test-e2e={dataTestE2e}
          >
            {processedFieldSet.map((fieldSet, fieldSetIndex) =>
              renderFieldSet(fieldSet, fieldSetIndex),
            )}

            {!nextPrev && onCancel && submitText && (
              <Container $carFileDetails={carFileDetails}>
                <ButtonsContainer>
                  <CancelButton
                    level="option"
                    onClick={onCancel}
                    disabled={isLoading || submitting}
                    noPadding
                  >
                    {cancelText || t('cancel')}
                  </CancelButton>
                  <LoadingButton
                    level={level}
                    type="submit"
                    isLoading={isLoading || submitting}
                    noPadding
                  >
                    {submitText}
                  </LoadingButton>
                </ButtonsContainer>
              </Container>
            )}

            {!nextPrev && submitText && !onCancel && (
              <Container $carFileDetails={carFileDetails}>
                <ButtonsContainer>
                  <LoadingButton
                    level={level}
                    data-test-e2e="complex-form-submit-button"
                    type="submit"
                    isLoading={isLoading || submitting}
                  >
                    {submitText}
                  </LoadingButton>
                </ButtonsContainer>
              </Container>
            )}

            {nextPrev && <Container $carFileDetails={carFileDetails}>
              <ButtonsContainer
                $hasStickyNav={hasStickyNav}
                data-test-e2e="buttons-container"
              >
                {nextPrev && submitText && (
                  <LoadingButton
                    type="submit"
                    isLoading={isLoading || submitting}
                  >
                    {submitText}
                  </LoadingButton>
                )}
                {nextPrev && (
                  <NextPrevCloseButtons
                    data-test-e2e="next-prev-close-buttons"
                    closeText={closeText}
                    nextTab={next && handleNext}
                    onClose={onClose ? (e) => handleClose(e, true) : null}
                    previousTab={previous && handlePrevious}
                    optionButtonText={optionButtonText}
                    optionButtonDisabled={
                      match.path === `${VALUATION}/2/basic` && !formValid
                    }
                    onOptionButtonClick={onOptionButtonClick}
                    hasStickyNav={hasStickyNav}
                    disableNextTabButton={!formValid}
                    disableCloseButton={!formValid}
                    {...(hasChevronInNextPrevCloseButtons &&
                      rightIconWithChevronProps)}
                  />
                )}
              </ButtonsContainer>
                         </Container>
            }
          </Form>
      )}
    </>
  )
}

ComplexForm.propTypes = {
  className: PropTypes.string,
  cancelText: PropTypes.string,
  closeText: PropTypes.string,
  data: PropTypes.object,
  feedback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.node,
  ]),
  fieldsets: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** The ID for the form state in redux which will allow forms to share
   * state if it is the same for multiple forms */
  formId: PropTypes.string.isRequired,
  /** If true, then the fieldsets are initially staticValueCollections if they have valid values */
  initiallyReadOnly: PropTypes.bool,
  invalid: PropTypes.bool, // From Redux Form: https://redux-form.com/8.3.0/docs/api/props.md/
  dontSubmitOnPrevious: PropTypes.bool,
  level: PropTypes.oneOf(['standard', 'cta']),
  next: PropTypes.bool,
  nextTab: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  openOverlay: PropTypes.func,
  previous: PropTypes.bool,
  previousTab: PropTypes.func,
  pristine: PropTypes.bool, // From Redux Form: https://redux-form.com/8.3.0/docs/api/props.md/
  scrollToTopOnError: PropTypes.bool,
  submitText: PropTypes.string,
  submitting: PropTypes.bool,
  singleColumn: PropTypes.bool,
  values: PropTypes.object.isRequired,
  /** Text in option button below Next Prev controls */
  optionButtonText: PropTypes.string,
  /** Function called from option button below Next Prev controls */
  onOptionButtonClick: PropTypes.func,
  isLoading: PropTypes.bool, // Set to true to set the submit button to the loading state
  finish: PropTypes.bool, // This argument is passed to the onSubmit handler
  hasStickyNav: PropTypes.bool,
  carFileDetails: PropTypes.bool,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
}

ComplexForm.defaultProps = {
  className: null,
  cancelText: null,
  closeText: null,
  data: null,
  feedback: null,
  initiallyReadOnly: false,
  dontSubmitOnPrevious: false,
  level: 'standard',
  next: null,
  nextTab: null,
  onCancel: null,
  onClose: null,
  onSubmit: null,
  openOverlay: null,
  previous: null,
  previousTab: null,
  pristine: true,
  scrollToTopOnError: false,
  submitText: null,
  submitting: false,
  singleColumn: false,
  optionButtonText: null,
  onOptionButtonClick: null,
  hasStickyNav: false,
  carFileDetails: false,
  hasChevronInNextPrevCloseButtons: false,
}

export default compose(
  connect((state, props) => ({
    form: props.formId,
    initialValues: getDefaultValues(props.data, props),
    /** This should be truthy if multiple forms with the same
     * id will get new data over time (like in CarEntryTabs)
     */
    enableReinitialize: props.enableReinitialize,
  })),
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  connect((state, props) => ({
    values: getFormValues(props.formId)(state),
  })),
)(ComplexForm)
