import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { getFlatFields, getFlatFieldSet } from 'utilities/form-utils'
import { scrollToTop, translateListItems } from 'utilities/utils'

import { useCarFileType } from 'hooks/use-car-file-type'

import { destinationOptions } from 'config/data'

import NextPrevCloseButtons, {
  nextPrevCloseButtonsPropTypes,
  rightIconWithChevronProps,
} from 'components/molecules/next-prev-close-buttons'
import FormField from 'components/molecules/form-field'
import Text from 'components/atoms/text'
import VerticalLayout from 'components/layouts/vertical-layout'

/**
 * Styling:
 */
const TotalField = styled(FormField)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
  * {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.brandBravo};
  }
  [class^='Field'] {
    border-bottom: 1px solid ${({ theme }) => theme.colors.brandBravo};
  }
`

const StyledFormField = styled(FormField)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const FormTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const FieldsetDescription = styled(Text)`
  display: block;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const ButtonsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  padding: ${({ theme }) => theme.sizings.lvl2} 0;
  width: 100%;
  z-index: 12;
  position: sticky;
  bottom: 0;
`

const getCalculatedValues = (formulas, vals) => {
  const newValues = { ...vals }

  formulas.forEach((f) => {
    newValues[f.outputField] = Math.round(parseFloat(f.formula(newValues)))
  })

  return newValues
}

/**
 * A CaculationForm can be given more than 1 form config (interpreted by ComplexForm)
 */
function CalculationForm({
  priceChecks,
  freeze,
  forms,
  formsOrdering,
  formulas,
  initialData,
  totalFields,
  nextTab,
  onSubmit,
  previous,
  previousTab,
  next,
  isFinalStep,
  submitText,
  valuation,
  hasStickyNav,
  hasChevronInNextPrevCloseButtons,
  optionButtonText,
  onOptionButtonClick,
  nextPrevCloseButtonsProps,
}) {
  const { t } = useTranslation()
  const [values, setValues] = useState(
    getCalculatedValues(formulas, initialData),
  )
  const destinationItems = translateListItems(destinationOptions, t)
  const [destination, setDestination] = useState(
    initialData.soort || destinationItems[0].value,
  )
  const [emptyRequiredFields, setEmptyRequiredFields] = useState([])
  const { isCarShareCarFile } = useCarFileType()

  const flatFieldSets = Object.values(forms).reduce(
    (result, vals) => [...result, ...vals],
    [],
  )
  const requiredFields = getFlatFields(flatFieldSets).reduce(
    (result, field) => (field.required ? [...result, field.name] : result),
    [],
  )

  const indexOfTaxatieWaarde = isCarShareCarFile
    ? formsOrdering.indexOf('topdown_inruilwaarde')
    : formsOrdering.indexOf('topdown_taxatiewaarde')

  useEffect(() => {
    if (initialData.soort && destination && destination !== initialData.soort) {
      onSubmit({ soort: destination })
    }
  }, [destination, onSubmit, initialData.soort])

  function updateForm(value, fieldName) {
    const newValues = {
      ...values,
      [fieldName]: Number(value),
    }

    const calculatedValues = getCalculatedValues(formulas, newValues)

    setValues(calculatedValues)
  }

  function handleSubmit(finish, skipValidation = false) {
    const eRequiredFields = requiredFields.reduce((result, field) => {
      const value = values[field]

      if (!value || value === 0 || value === '0') {
        return [...result, field]
      }
      return result
    }, [])

    setEmptyRequiredFields(eRequiredFields)

    if (skipValidation === false && eRequiredFields.length) {
      scrollToTop()
      return false
    }

    return onSubmit({ ...values, destination }, finish)
  }

  function onClose() {
    handleSubmit(true, true)
  }

  function handleNext() {
    const submitted = handleSubmit()

    if (submitted) {
      nextTab()
    }
  }

  function handlePrevious() {
    const submitted = handleSubmit()

    if (submitted) {
      previousTab()
    }
  }

  const handleOnOptionButtonClick = () => {
    return onOptionButtonClick({ ...values, destination })
  }

  const generateForm = (id) => {
    const fields = getFlatFields(forms[id])
    const fieldSet = getFlatFieldSet(forms[id])

    return (
      <form key={id}>
        <span>
          <legend>
            {fieldSet.title && (
              <FormTitle
                text={fieldSet.title}
                type={fieldSet.type === 'small' ? 'floatingLabelLabel' : 'h2'}
              />
            )}
          </legend>
          {forms[id].description && (
            <FieldsetDescription
              type="floatingLabelLabel"
              text={t(fieldSet.description)}
            />
          )}
        </span>
        {fields.map((fieldConfig) =>
          totalFields.indexOf(fieldConfig.name) > -1 ? (
            <TotalField
              key={fieldConfig.name}
              field={fieldConfig}
              error={emptyRequiredFields.indexOf(fieldConfig.name) !== -1}
              fieldValueIndex={0}
              handleChange={updateForm}
              defaultValues={[initialData[fieldConfig.name]]}
              value={[values[fieldConfig.name]]}
            />
          ) : (
            <StyledFormField
              key={fieldConfig.name}
              field={{
                ...fieldConfig,
                readOnly: fieldConfig.readOnly || freeze,
              }}
              error={emptyRequiredFields.indexOf(fieldConfig.name) !== -1}
              fieldValueIndex={0}
              handleChange={updateForm}
              defaultValues={[initialData[fieldConfig.name]]}
              value={[values[fieldConfig.name]]}
            />
          ),
        )}
      </form>
    )
  }

  return (
    <>
      <VerticalLayout hasRestrictedWidth>
        {valuation && (
          <form>
            <span>
              <legend>
                <FormTitle text={t('destination')} type="h2" />
              </legend>
            </span>
            <StyledFormField
              key="destination"
              field={{
                name: 'soort',
                title: t('kind'),
                type: 'select',
                options: destinationItems,
                readOnly: freeze || isCarShareCarFile,
              }}
              fieldValueIndex={0}
              handleChange={(val) => setDestination(val)}
              defaultValues={initialData.soort || destinationItems[0].value}
              value={destination}
            />
          </form>
        )}

        {indexOfTaxatieWaarde !== -1 ? (
          <>
            {formsOrdering
              .slice(0, indexOfTaxatieWaarde + 1)
              .map((id) => generateForm(id))}
            {priceChecks}
            {formsOrdering
              .slice(indexOfTaxatieWaarde + 1)
              .map((id) => generateForm(id))}
          </>
        ) : (
          <>{formsOrdering.map((id) => generateForm(id))}</>
        )}
      </VerticalLayout>
      <ButtonsContainer>
        <NextPrevCloseButtons
          nextTab={next ? handleNext : null}
          closeText={submitText}
          onClose={isFinalStep ? onClose : handleSubmit}
          previousTab={previous ? handlePrevious : null}
          hasStickyNav={hasStickyNav}
          optionButtonText={optionButtonText}
          onOptionButtonClick={handleOnOptionButtonClick}
          {...nextPrevCloseButtonsProps}
          {...(hasChevronInNextPrevCloseButtons && rightIconWithChevronProps)}
        />
      </ButtonsContainer>
    </>
  )
}

CalculationForm.propTypes = {
  priceChecks: PropTypes.node,
  freeze: PropTypes.bool,
  forms: PropTypes.objectOf(PropTypes.array).isRequired,
  formsOrdering: PropTypes.arrayOf(PropTypes.string).isRequired,
  formulas: PropTypes.arrayOf(
    PropTypes.shape({
      outputField: PropTypes.string.isRequired,
      formula: PropTypes.func.isRequired,
    }),
  ).isRequired,
  totalFields: PropTypes.array,
  initialData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  previous: PropTypes.bool,
  previousTab: PropTypes.func,
  next: PropTypes.bool,
  nextTab: PropTypes.func,
  isFinalStep: PropTypes.bool,
  submitText: PropTypes.string,
  valuation: PropTypes.bool,
  hasStickyNav: PropTypes.bool,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
  /** Text in option button below Next Prev controls */
  optionButtonText: PropTypes.string,
  /** Function called from option button below Next Prev controls */
  onOptionButtonClick: PropTypes.func,
  nextPrevCloseButtonsProps: PropTypes.shape(nextPrevCloseButtonsPropTypes),
}

CalculationForm.defaultProps = {
  priceChecks: '',
  freeze: false,
  totalFields: [],
  previous: false,
  next: false,
  nextTab: null,
  isFinalStep: false,
  previousTab: null,
  submitText: null,
  valuation: false,
  hasStickyNav: false,
  hasChevronInNextPrevCloseButtons: false,
  optionButtonText: null,
  onOptionButtonClick: null,
}

export default CalculationForm
