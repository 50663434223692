/* eslint-disable react/prop-types */
import React, { useContext, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useMediaLayout } from 'use-media'
import { useSelector } from 'react-redux'

import { getTranslatedForms } from 'utilities/form-utils'
import CarfileSummary from 'components/organisms/carfile-summary'
import { scrollTo } from 'utilities/utils'
import { media } from 'utilities/styled'

import { CAR_SHARE_CAR_FILE } from 'config/routes'
import {
  getCarFileStatusFromV3Value,
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'

import LoadingIndicator from 'components/atoms/loading-indicator'
import Tabs from 'components/layouts/tabs'
import EquipmentForm from 'components/redux-containers/equipment-form-container'
import DamageForm from 'components/organisms/damage-form'
import BidsForm from 'components/redux-containers/bids-form-container'
import CarDataForm from 'components/molecules/cardata-form'
import CarLayersFormContainer from 'components/redux-containers/car-layers-form-container'
import PortalsForm from 'components/organisms/portals-form'
import TopdownCalculationForm from 'components/redux-containers/topdown-calculation-form-container'
import TopdownBpmCalculationForm from 'components/organisms/topdown-bpm-calculation-form'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import Text from 'components/atoms/text'
import ManagementInfo from 'components/views/car-file/tabs/management-info'
import BpmCalculation from 'components/views/car-file/tabs/bpm/bpm-calculation'
import BpmDocuments from 'components/views/car-file/tabs/bpm/bpm-documents'
import CarAssetsFormForMedia from 'components/views/car-file/tabs/media'
import CarAssetsFormForDocuments from 'components/views/car-file/tabs/documents'
import Checklists from 'components/views/car-file/tabs/checklists/checklists-tab'

import useDealerLocationCountryCode from 'hooks/use-location-country-code'

const Container = styled.div`
  display: grid;
  min-height: 200px;
  width: 100%;
`

const StyledTabs = styled(Tabs)`
  > .TabList {
    position: sticky;
    top: ${({ theme }) => theme.sizeByFactor(7.5)};
    background: ${({ theme }) => theme.colors.brandFoxtrot};
    z-index: 11;
  }

  ${media.desktop`
    > .TabList {
      position: sticky;
      top: ${({ theme }) => theme.sizeByFactor(22)};
      background: ${({ theme }) => theme.colors.brandFoxtrot};
      z-index: 11;
    }
  `}
`

function CarfileData({
  advertImages,
  addCarAssets,
  carAssetsSort,
  carFile,
  carKind,
  carId,
  documents,
  deleteCarAssets,
  forms,
  getForms,
  getBids,
  id,
  loading,
  match,
  location,
  rotateImages,
  sortCarAssets,
  updateCarfile,
  userDetails,
  carAssets,
  openOverlay,
  openImageOverlay,
  setCarKind,
  toggleSalesView,
  setCarStatus,
  carStatus,
  history,
  carfileSummary,
  ...restProps
}) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const salesView = useSelector((state) => state.ui.salesView)
  const [values, setValues] = useState()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const isPhone = useMediaLayout({ maxWidth: theme.metrics.phone - 1 })
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  const confirmPublish =
    carFile && Array.isArray(carFile.confirmPublish)
      ? carFile.confirmPublish
      : []

  const isMotorbikeOrMicrocar = ['brommobiel', 'motor'].includes(
    carFile?.voertuigsoort_mapped,
  )

  /**
   * The Checklists component contains a Tabs component, which triggers a bunch of url changes when
   * switching tabs. This causes unneccesary re-rendering and re-fetching of data. Putting the
   * Checklists component into a useCallback will prevent this from happening.
   **/
  const carFileStatus = getCarFileStatusFromV3Value(carFile?.status)
  const ChecklistsComponent = useCallback(
    (props) =>
      carId ? (
        <Checklists
          carFileId={carId}
          hasDestination={!!carFile?.kind}
          status={carFileStatus}
          {...props}
        />
      ) : (
        <></>
      ),
    [carId, carFileStatus, carFile],
  )
  const dealerIsAllowedToUseChecklist = useSelector((state) => {
    if (Array.isArray(state?.auth?.userDetails?.dealers) && carFile) {
      const result = state.auth.userDetails.dealers.some((dealer) => {
        return dealer.dealernr === carFile.dealernr && dealer.rechten.flexflow
      })
      return result
    }
    return false
  })

  const userIsAllowedToUseChecklist = useSelector(
    (state) => !!state?.auth?.userDetails?.flexflow,
  )

  const calculationPathRegex = new RegExp(
    `${CAR_SHARE_CAR_FILE}/[0-9]+/calculation$`,
    'i',
  )
  const isCalculationTab = calculationPathRegex.test(location.pathname)

  useEffect(() => {
    getForms('bids', { auto_id: carId })
    getBids({ auto_id: carId })
  }, [getForms, getBids, carId])

  useEffect(() => {
    if (!isCalculationTab) {
      return
    }
    // Re-get the carfile forms (show in the topdown config) to always show an updated file
    // There's a use-case where the user might add damages, and these need to be updated once the tab switches
    getForms('carfile', { auto_id: carId })
  }, [isCalculationTab, getForms, carId])

  if (loading) {
    return (
      <Container>
        <LoadingIndicator />
      </Container>
    )
  }

  async function submitUpdate(vals) {
    await updateCarfile(vals, carId)
    await getForms('carfile', { auto_id: carId })
    return true
  }

  function handleSubmit(fields, tabId) {
    if (tabId && confirmPublish.indexOf(tabId) !== -1) {
      // Store values in local state for the submit / cancel handler
      // of the modal to be able to pass them to the submitUpdate
      // function
      setValues(fields)
      setConfirmModalOpen(true)
      return true
    }
    return submitUpdate(fields)
  }

  function handleSubmitModal(vals, publish) {
    submitUpdate({
      ...vals,
      confirmedPublish: publish,
    })
    setConfirmModalOpen(false)
  }

  const translatedForms = getTranslatedForms(t, forms)

  const carFileFormData = {
    ...carFile,
    ...carKind,
  }

  const advertisementItems = []

  if (translatedForms.advertisementDetailsForm) {
    advertisementItems.push({
      label: t('details'),
      to: `${match.url}/advertisement/details`,
      component: (props) => (
        <CarDataForm
          carFile={carFileFormData}
          fieldsets={translatedForms.advertisementDetailsForm}
          formId="carfileAdvertisementDetails"
          level="cta"
          onSubmit={(fields) => handleSubmit(fields, 'details')}
          submitText={t('saveChanges')}
          {...props}
        />
      ),
    })
  }

  if (translatedForms.advertisementDetailsForm) {
    advertisementItems.push({
      label: t('portals'),
      to: `${match.url}/advertisement/portals`,
      component: (props) => (
        <PortalsForm
          carId={carId}
          // eslint-disable-next-line react/prop-types
          portals={carFile.portals}
          // eslint-disable-next-line react/prop-types
          published={carFile.verzonden}
          disabled={!carFile.advertisementTabEnabled}
          disabledMessage={carFile.advertisementTabStatusDescription}
          onSubmit={(fields) => handleSubmit(fields, 'portals')}
          {...props}
        />
      ),
    })
  }

  if (translatedForms.advertisementLayersForm) {
    advertisementItems.push({
      label: t('layers'),
      to: `${match.url}/advertisement/layers`,
      component: (props) => (
        <CarLayersFormContainer
          carFile={carFile}
          onSubmit={(payload) => handleSubmit(payload, 'layers')}
          {...props}
        />
      ),
    })
  }

  if (translatedForms.advertisementLeaseForm) {
    advertisementItems.push({
      label: t('lease'),
      to: `${match.url}/advertisement/lease`,
      component: (props) => (
        <CarDataForm
          carFile={carFileFormData}
          fieldsets={translatedForms.advertisementLeaseForm}
          formId="carfileAdvertisementLease"
          level="cta"
          onSubmit={(fields) => handleSubmit(fields, 'lease')}
          submitText={t('saveChanges')}
          {...props}
        />
      ),
    })
  }

  const carImages =
    carAssets &&
    carAssets.data &&
    carAssets.data.filter(({ type }) => type === 'image')

  const tabItems = []
  tabItems.push({
    label: t('carFileSummary.tabTitle'),
    to: `${match.url}/summary`,
    component: () => (
      <CarfileSummary
        carfile={carfileSummary.data}
        carfileLoading={carfileSummary.loading}
        carId={match.params.id}
        images={carImages}
        imagesLoading={carAssets ? carAssets.loading : true}
        openOverlay={openOverlay}
        openImageOverlay={openImageOverlay}
        setCarKind={setCarKind}
        salesView={salesView}
        toggleSalesView={toggleSalesView}
        carStatus={carStatus}
        setCarStatus={setCarStatus}
        updateCarfile={updateCarfile}
        showInternalPricingInfo
        showStayTime
        onProgressClick={() => {
          if (!history.location.pathname.match(/\/flexflows$/)) {
            history.push(`${match.url}/flexflows`)
          }
          const element = document.getElementById('data')
          if (element) {
            scrollTo(element)
          }
        }}
        showCspCalculationButton={!!userDetails?.csp}
        data-test-e2e="car-file-summary"
      />
    ),
    noPadding: true,
  })

  if (
    userIsAllowedToUseChecklist &&
    dealerIsAllowedToUseChecklist &&
    !salesView
  ) {
    tabItems.push({
      label: t('carFileChecklists.tab.tabTitle'),
      to: `${match.url}/flexflows`,
      component: ChecklistsComponent,
    })
  }

  tabItems.push({
    label: t('specifications'),
    to: `${match.url}/specifications`,
    component: (props) => (
      <CarDataForm
        carFile={carFileFormData}
        fieldsets={translatedForms.specificationsGeneralForm}
        formId="carfileSpecifications"
        level="cta"
        onSubmit={handleSubmit}
        submitText={t('saveChanges')}
        {...props}
        next
      />
    ),
  })

  tabItems.push({
    label: t('history'),
    to: `${match.url}/history`,
    component: (props) => (
      <CarDataForm
        carFile={carFileFormData}
        fieldsets={translatedForms.specificationsHistoricalForm}
        formId="carfileHistory"
        level="cta"
        onSubmit={handleSubmit}
        submitText={t('saveChanges')}
        {...props}
        next
      />
    ),
  })

  tabItems.push({
    label: t('equipment'),
    to: `${match.url}/equipment`,
    component: (props) => (
      <EquipmentForm
        // eslint-disable-next-line react/prop-types
        carAccessories={carFile.accessoires}
        formId="carfileEquipment"
        onSubmit={handleSubmit}
        submitText={t('saveChanges')}
        {...props}
        next
      />
    ),
  })

  tabItems.push({
    label: t('media'),
    to: `${match.url}/media`,
    component: (props) => (
      <CarAssetsFormForMedia
        carFile={carFileFormData}
        deleteCarAssets={deleteCarAssets}
        sortCarAssets={sortCarAssets}
        carFileId={carId}
        rotateImages={rotateImages}
        {...props}
        next
      />
    ),
  })

  if (!isMotorbikeOrMicrocar) {
    tabItems.push({
      label: t('damage'),
      to: `${match.url}/damage`,
      component: (props) => (
        <DamageForm
          carId={carId}
          rotateImages={rotateImages}
          {...props}
          next
          carFileDetails
        />
      ),
    })
  }

  if (carFile && carFile.topdown_config && !carFile?.hasBpmCalculation) {
    tabItems.push({
      label: t('calculation'),
      to: `${match.url}/calculation`,
      component: (props) => (
        <TopdownCalculationForm
          car={carFile}
          // eslint-disable-next-line react/prop-types
          config={carFile.topdown_config}
          forms={forms}
          onSubmit={handleSubmit}
          {...props}
          next
        />
      ),
    })
  }

  if (carFile && carFile?.hasBpmCalculation) {
    tabItems.push({
      label: t('calculation'),
      to: `${match.url}/calculation`,
      component: () => <TopdownBpmCalculationForm />,
    })
  }

  if (!isMotorbikeOrMicrocar) {
    tabItems.push({
      label: t('bids'),
      to: `${match.url}/bids`,
      component: (props) => (
        <BidsForm carFile={carFile} {...props} next carFileDetails />
      ),
    })
  }

  tabItems.push({
    label: t('documents'),
    to: `${match.url}/documents`,
    component: (props) => (
      <CarAssetsFormForDocuments
        addCarAssets={addCarAssets}
        carFile={carFileFormData}
        carFileId={carId}
        deleteCarAssets={deleteCarAssets}
        carFileDetails
        // items={documents}
        // eslint-disable-next-line react/prop-types
        next={
          carFile.status !==
          CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend]
        }
        level="standard"
        sortCarAssets={sortCarAssets}
        {...props}
      />
    ),
  })

  if (
    carFile.status !==
    CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend]
  ) {
    tabItems.push({
      label: t('advertise'),
      to: `${match.url}/advertisement/details`,
      matchTo: `${match.url}/advertisement/:subitem`,
      subItems: advertisementItems,
    })
  }

  if (
    !isPhone &&
    carFile.status !==
      CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend] &&
    carFile.status !==
      CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verwacht] &&
    locationCountryCodeIsNl
  ) {
    tabItems.push({
      label: t('managementInfo.page.metaTitle'),
      to: `${match.url}/price-management`,
      component: () => <ManagementInfo carFile={carFileFormData} />,
    })
  }

  if (carFile?.hasBpm) {
    const bpmSubItems = []

    bpmSubItems.push({
      label: t('dataInput'),
      to: `${match.url}/bpm/data`,
      component: (props) => (
        <CarDataForm
          carFile={carFileFormData}
          fieldsets={translatedForms.eurotaxbpmbepaling}
          formId="bpmDeclaration"
          level="cta"
          onSubmit={handleSubmit}
          submitText={t('saveChanges')}
          {...props}
        />
      ),
    })

    bpmSubItems.push({
      label: t('bpmCalculation.bpmCalculation'),
      to: `${match.url}/bpm/calculation`,
      component: () => <BpmCalculation />,
    })

    bpmSubItems.push({
      label: t('documents'),
      to: `${match.url}/bpm/documents`,
      component: () => <BpmDocuments />,
    })

    tabItems.push({
      label: t('bpm'),
      to: `${match.url}/bpm/data`,
      matchTo: `${match.url}/bpm/:subitem`,
      subItems: bpmSubItems,
    })
  }

  return (
    <Container id={id} {...restProps}>
      <StyledTabs items={tabItems}>
        {tabItems.map((item, index) =>
          item.subItems ? (
            <Tabs items={item.subItems} key={index.toString()} small>
              {item.subItems.map((subItem, subIdx) => (
                <subItem.component key={subIdx.toString()} />
              ))}
            </Tabs>
          ) : (
            <item.component key={index.toString()} />
          ),
        )}
      </StyledTabs>
      {confirmModalOpen && (
        <FlexibleDialog
          content={<Text text={t('confirmPublishToPortals')} />}
          cancelText={t('noJustSave')}
          onCancel={() => handleSubmitModal(values, false)}
          onSubmit={() => handleSubmitModal(values, true)}
          submitText={t('yesPublish')}
          title={`${t('publishToPortals')}?`}
          cancel
          open
        />
      )}
    </Container>
  )
}

CarfileData.propTypes = {
  addCarAssets: PropTypes.func.isRequired,
  advertImages: PropTypes.array,
  carAssetsSort: PropTypes.object,
  carFile: PropTypes.object,
  carKind: PropTypes.object,
  carId: PropTypes.string.isRequired,
  deleteCarAssets: PropTypes.func.isRequired,
  documents: PropTypes.array,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  getBids: PropTypes.func.isRequired,
  id: PropTypes.string,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  rotateImages: PropTypes.func.isRequired,
  sortCarAssets: PropTypes.func.isRequired,
  updateCarfile: PropTypes.func.isRequired,
}

CarfileData.defaultProps = {
  advertImages: null,
  carAssetsSort: null,
  carFile: null,
  carKind: null,
  documents: null,
  forms: null,
  id: null,
}

export default withRouter(CarfileData)
