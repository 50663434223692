import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import FilterCollapser from 'components/molecules/filter-collapser'
import RadioButton from 'components/atoms/radio-button'
import { toKebabCase } from 'utilities/format'

const StyledRadioButton = styled(RadioButton)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  label {
    hyphens: auto;
    word-wrap: normal;
    overflow-wrap: break-word;
  }
`

/**
 * Allows a user to select an option from a list of prefined
 * options.
 */
function RadioFilter({
  name,
  filterOptions,
  initallyOpen,
  onFilterUpdate,
  selectedFilter,
  title,
}) {
  return (
    <FilterCollapser initallyOpen={initallyOpen} title={title}>
      <>
        {filterOptions.map((option, index) => (
          <StyledRadioButton
            key={index.toString()}
            checked={selectedFilter === String(option.value)}
            id={`${name}_${option.value}`}
            name={`${name}_${option.value}`}
            onChange={(e) => onFilterUpdate(name, e.target.value)}
            selected={selectedFilter}
            title={option.label}
            value={String(option.value)}
            data-test-e2e={toKebabCase(`filter-option-${name}-${option.value}`)}
          />
        ))}
      </>
    </FilterCollapser>
  )
}

RadioFilter.propTypes = {
  name: PropTypes.string.isRequired,
  filterOptions: PropTypes.array.isRequired,
  initallyOpen: PropTypes.bool,
  onFilterUpdate: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

RadioFilter.defaultProps = {
  initallyOpen: null,
}

export default RadioFilter
