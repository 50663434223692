/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { translateForm } from 'utilities/form-utils'
import configForms from 'config/forms'

import Tabs from 'components/layouts/tabs'
import CarDataForm from 'components/molecules/cardata-form'
import DamageForm from 'components/organisms/damage-form'
import BidsForm from 'components/redux-containers/bids-form-container'
import TopdownCalculationForm from 'components/redux-containers/topdown-calculation-form-container'
import EquipmentForm from 'components/redux-containers/equipment-form-container'
import CarAssetsFormForMedia from 'components/views/car-file/tabs/media'

function CarEntryValuationTabs({
  car,
  formId,
  forms,
  history,
  manual,
  match,
  onChange,
  updateCarfile,
  handleSubmit,
  deleteCarAssets,
  sortCarAssets,
  rotateImages,
  ...restProps
}) {
  const { t } = useTranslation()
  const carVersionFieldsets = manual
    ? configForms.licensePlate.data.valuation.fieldsets
    : []
  const basicsForm = translateForm(forms.basics_form.fieldsets, t)

  const carId = car.auto_id
  const isMotorbikeOrMicrocar = ['brommobiel', 'motor'].includes(
    car.voertuigsoort_mapped,
  )

  const goToCar = useCallback(() => {
    history.push(`/car/${carId}/media#published`)
  }, [carId, history])

  const handleOnOptionButtonClick = async () => {
    // Use the logic defined in car-entry-valuation-step-2 to submit the form
    const submit = await onChange(undefined, undefined, 'flow-stepper')

    // Redirect to the car file
    if (submit === true) {
      if (!carId) {
        alert(t('enterDetailsAndSaveFirst'))
      } else {
        goToCar()
      }
    }
  }

  const tabItems = [
    {
      key: 'basic',
      label: t('basicData'),
      to: `${match.url}/basic`,
      component: (props) => (
        <CarDataForm
          initiallyReadOnly
          carFile={car}
          fieldsets={[...carVersionFieldsets, ...basicsForm]}
          formId={formId}
          level="cta"
          onSubmit={handleSubmit}
          manual={manual}
          valuation
          {...props}
          optionButtonText={t('save')}
          onOptionButtonClick={handleOnOptionButtonClick}
          hasChevronInNextPrevCloseButtons
          next
          scrollToTopOnError
          data-test-e2e="car-data-form"
        />
      ),
    },
    {
      key: 'equipment',
      disabled: !carId,
      label: t('equipment'),
      to: `${match.url}/equipment`,
      component: (props) => (
        <EquipmentForm
          {...props}
          extraData={{
            ...car,
            // eslint-disable-next-line react/prop-types
            accessoires_data_edition: car.accessoires_data_edition,
            // eslint-disable-next-line react/prop-types
            accessoires_data_licenseplate: car.accessoires_data_licenseplate,
            // eslint-disable-next-line react/prop-types
            altcode: car.altcode,
          }}
          // eslint-disable-next-line react/prop-types
          carAccessories={car.accessoires}
          formId={formId}
          onSubmit={handleSubmit}
          optionButtonText={t('save')}
          onOptionButtonClick={handleOnOptionButtonClick}
          manual={manual}
          hasChevronInNextPrevCloseButtons
          valuation
          next
          data-test-e2e="equipment-form"
        />
      ),
    },
    {
      key: 'damage',
      disabled: !carId,
      label: t('damage'),
      to: `${match.url}/damage`,
      component: (props) => (
        <DamageForm
          carId={carId}
          {...props}
          hasChevronInNextPrevCloseButtons
          next
          optionButtonText={t('save')}
          onOptionButtonClick={() => goToCar()} // Data is saved during form actions, no need to submit, just redirect
          data-test-e2e="damage-form"
          rotateImages={rotateImages}
        />
      ),
    },
    {
      key: 'media',
      disabled: !carId,
      label: t('media'),
      to: `${match.url}/media`,
      component: (props) => (
        <CarAssetsFormForMedia
          carFile={car}
          carFileId={carId}
          deleteCarAssets={deleteCarAssets}
          sortCarAssets={sortCarAssets}
          rotateImages={rotateImages}
          optionButtonText={t('save')}
          onOptionButtonClick={() => goToCar()} // Data is saved during form actions, no need to submit, just redirect
          {...props}
          hasChevronInNextPrevCloseButtons
          next
          isValuation
        />
      ),
    },
    {
      key: 'value',
      disabled: !carId,
      label: t('value'),
      to: `${match.url}/value`,
      component: (props) => (
        <BidsForm
          carFile={car}
          {...props}
          updateCarfile={updateCarfile}
          valuation
          hasChevronInNextPrevCloseButtons
          next
          optionButtonText={t('save')}
          onOptionButtonClick={() => goToCar()} // Data is saved during form actions, no need to submit, just redirect
          data-test-e2e="bids-form"
        />
      ),
    },
    {
      key: 'calculation',
      disabled: !carId,
      label: t('calculation'),
      to: `${match.url}/calculation`,
      component: (props) => (
        // This ID is used to fetch all forms inside TopDownCalculationForm
        // see the handleChangeStep function in car-entry-valuation-step2
        <div id="valuation-top-down-calculation-form">
          <TopdownCalculationForm
            car={car}
            // eslint-disable-next-line react/prop-types
            config={car.topdown_config}
            forms={forms}
            onSubmit={handleSubmit}
            submitText={t('goToCarFile')}
            {...props}
            hasChevronInNextPrevCloseButtons
            isValuation
            isFinalStep
            data-test-e2e="topdown-calculation-form"
            optionButtonText={t('save')}
            onOptionButtonClick={handleSubmit}
          />
        </div>
      ),
    },
  ]

  // When we're adding a motorbike or microcar, remove the "damage" and "value" tabs
  const filteredTabItems = isMotorbikeOrMicrocar
    ? tabItems.filter((item) => !['damage', 'value'].includes(item.key))
    : tabItems

  return (
    <Tabs
      items={filteredTabItems}
      onChange={onChange}
      hideNavigation
      addStickyNavMargin
      {...restProps}
    >
      {filteredTabItems.map((item, index) => (
        <item.component key={index.toString()} />
      ))}
    </Tabs>
  )
}

CarEntryValuationTabs.propTypes = {
  car: PropTypes.object.isRequired,
  forms: PropTypes.object,
  manual: PropTypes.bool,
  onChange: PropTypes.func,
  deleteCarAssets: PropTypes.func.isRequired,
  sortCarAssets: PropTypes.func.isRequired,
  rotateImages: PropTypes.func.isRequired,
}

CarEntryValuationTabs.defaultProps = {
  forms: {},
  manual: false,
  onChange: undefined,
}

export default withRouter(CarEntryValuationTabs)
